.trial{
  text-align: center;
  color: #000000;
  &__logo{
    margin-bottom: 53px;
    &-second{
      cursor: pointer;
    }
  }
  &__text{
    font-size: 36px;
    line-height: 20px;
    text-align: center;
 
    margin-bottom: 60px;
  }
  &__sub-text{
    font-size: 23px;
    line-height: 41px;
    &-second{
      margin-top: 23px;
      margin-bottom: 100px;
    }
  }
  &__question{
    font-size: 16px;
    line-height: 20px;
    margin-top: 61px;
  }
  &__link, &__link:hover{
    color: #eb672d;
    text-decoration: unset;
  }
}