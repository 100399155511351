@import "../../../styles/variables";

.inline-form-group {
  display: grid;
  grid-template-columns: minmax(50px, auto) minmax(auto, 1fr);
  column-gap: 20px;
  align-content: center;
  min-height: 50px;
  border-bottom: 1px solid $border-color-light;
  margin-bottom: 0;
  &:last-child {
    margin-bottom: 0px;
    border-bottom: none;
  }
  .ifg-label {
    display: inline-flex;
    align-items: center;
    height: 100%;
    color: $text-secondary-color-light;
    margin-bottom: 0;
  }
  .ifg-input {
    text-align: right;
    color: $text-primary-color;
    &:focus {
      outline: none;
    }
    &:not(:hover) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &:not(.readonly) {
    border-bottom-color: transparent;
  }
}