
.top-label-search-mutiple-group {
 .top-label-form-group__icon{
  right: 10px;
  left: unset;
 }
 .top-label-form-group__search input{
   padding-left: 8px !important;
 }
 &__parent{
   position: relative;
 }
 
 &__values{
  width: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  margin-top: 9px;
  border: 1px solid #04031a1a;
  padding-top: 5px;
  padding-bottom: 5px;
  &-second{
    position: absolute;
    top: 33px;
    z-index: 10000;
    max-height: 300px;
    overflow: auto;

  }
  &__item{
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    color: #04031A;
    padding: 5px 10px;
    &-second{
      cursor: pointer;
    }
    svg{
      margin-left: auto;
      cursor: pointer;
    }
    &:hover{
      background: linear-gradient(
        0deg
        , rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #273137;
    }
  }
 }
}
