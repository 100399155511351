.programView{
  &__combox{
    margin-left: 39px;
    .top-label-dropdown-group__dropDown .dropdown-toggle {
      width: 230px;
    }
    
  }
 .topFilterTable__button-add{
  min-width: 140px;
 }
 .tooltip-drawer{
   float: left;
   padding-left: 3px;
 }
}