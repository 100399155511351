@import "../../../styles/variables";

.top-label-dropdown-group {
  position: relative;
  margin-bottom: 0px;
  &__label{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(4, 3, 26, 0.5);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    margin-top: 12px;
  }
  &__dropDown{
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    .dropdown-menu{
      max-height: 600px;
      overflow-y: auto;
    }
  
    .dropdown-toggle{
      border: 1px solid #e4e4e4 !important;
      background: #FFFFFF !important;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      height: 40px;
      align-items: center;
      color: #273137 !important;
      width: 100%;
      &::after{
        display: none;
      }
     svg{
       margin-left: auto;

     }
    }
    .dropdown-item:hover{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #273137 !important;
     
    }
    .dropdown-menu{
      width: 100%
    }
    .dropdown-item{
      color: #273137 !important;
    }
    .dropdown-item-mutipule{
      color: #000000!important;
      box-shadow: inset 0px -1px 0px #e4e4e4;
      font-size: 14px;
      display: flex;
      align-items: center;
      &__icon{
        margin-right: 12px;
        height: 10px;
        width: 13px;
        &-open{
          transform: rotate(90deg);
        }
        &__second{
          margin-left: 20px !important;
        }
      }
      &__content{
        display: flex;
        align-items: center;
        width: 100%;
        &__text{
          white-space: break-spaces;
          width: 100%;
        }
      }
      
    }
  }
  &__placeholder{
    .dropdown-toggle{
    color: rgba(4, 3, 26, 0.5) !important;
    }
  }
  &__search{
    position: relative;
    input{
      padding-left: 36px !important;
    }
  }
  &__icon{
    position: absolute;
    top: 11px;
    left: 10px;
  }
  .form-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(4, 3, 26, 0.5);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  .subLabel {
    margin-left: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  &.form-input {
    input {
      background: #ffffff;
      border: 1px solid rgba(4, 3, 26, 0.1);
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #04031a;
      padding-left: 8px;
      height: 40px;
    }
  }

  .form-control,
  .form-control-plaintext {
    width: 100%;
    color: $text-primary-color;
    border-radius: 0px;
    &:focus {
      outline: none;
      box-shadow: unset;
    }
  }

  .message {
    position: relative;
    padding-left: 15px;
    font-weight: $font-weight-light;
    font-size: $font-14;
    color: $color-black-light;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 12px;
      height: 12px;
      transform: translate(0, -50%);
    }
  }

  &.success {
    &.form-input {
      input {
        border: 1px solid $color-success;
      }
    }
    .message {
      &::before {
        background: url(../../../assests/icons/success.svg) no-repeat center center;
      }
    }
  }

  &.warning {
    &.form-input {
      input {
        border: 1px solid $color-warning;
      }
    }
    .message {
      &::before {
        background: url(../../../assests/icons/warning.svg) no-repeat center center;
      }
    }
  }

  &.danger {
    &.form-input {
      input {
        border: 1px solid $color-danger;
      }
    }
    .message {
      &::before {
        background: url(../../../assests/icons/danger.svg) no-repeat center center;
      }
    }
  }
}

.top-label-dropdown-group.danger{
  .top-label-dropdown-group__dropDown {
    border: 1px solid #B50E37;
    border-radius: 4px;
  }
}
.top-label-dropdown-group__dropDown .dropdown-toggle svg{
  min-width: 10px;
  margin-left: auto;
  
}

.top-label-dropdown-group.warning{
  .dropdown-toggle {
    border: 1px solid #c19e3f  !important;
    background: #fef9e9 !important;
    color: #c19e3f  !important;
    border-radius: 4px;
  }
}