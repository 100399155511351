// -- Variables -->
@import "../../../styles/variables";

.custom-contact-group {
  font-size: inherit;
  &_blur{
    outline: none !important;
  }
  .custom-icon-button{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #9f9faa;
  }
  .btn{
    padding: 6px 10px;
    align-items: center;
    display: flex;
  }
  .btn-primary{
    background-color: white;
    border-color: #9f9faa;
    border-width: 1.5px;
  }
  .btn-primary:hover{
    background-color: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #273137;
    transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    path {
      fill: #273137;
      transition: fill 400ms ease-in-out;
      -webkit-transition: fill 400ms ease-in-out;
    }
  }

  .btn-primary.active{
    background-color: $slate !important;
    border-color: $slate !important;
    path {
      fill: white;
    }
  }
  &_chevron{
    display: none;
  }
  &_dropdown{
    display: none;
  }
  @media (max-width: 768px) {
    &_dropdown {
      display: unset;
      position: absolute;
      top: 53px;
      width: 100%;
      left: 0;
      right: 0;
      background: #FFFFFF;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
      &-close{
        display: none !important;
        
        transition: all 400ms ease-in-out;
        -webkit-transition: all 400ms ease-in-out;
      }
    }
    
   
    &_item{
      display: flex;
      align-items: center;
      padding: 18px 68px;
      padding-right: unset;
      cursor: pointer;
      box-shadow: inset 0px -1px 0px rgba(74, 73, 86, 0.25);
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #273137;
      path{
        fill: #273137;
      }
      &_text{
        margin-left: 10px;
        font-size: 16px;
        line-height: 20px;
        color: #273137;
      }
      svg{
        height: 15px;
        width: 15px;
      }
    }
    &_item.active{
      background: $slate ;
      box-shadow: inset 0px -1px 0px rgba(74, 73, 86, 0.25) ;
      path{
        fill: #fff
      }
      .custom-contact-group_item_text{
        color: #fff;
      }
    }
    &_item.disable{
      background: #FFFFFF;
      box-shadow: none;
      path{
        fill: #cfcfd5
      }
      .custom-contact-group_item_text{
        color: #cfcfd5;
      }
    }
    &_parent{
      display: flex;
    }
    &_chevron{
      display: unset;
      width: 24px;
      height: 32px;
      border: 1px solid #9f9faa;
      border-top-right-radius: 4px ;
      border-bottom-right-radius: 4px ;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    &_chevron.active{
      border: 1px solid #273137;
      background: #273137;
      path{
        fill: #fff
      }
    }
    .custom-icon-button {
      display: none ;
    }
    .custom-icon-button.active, .custom-icon-button.default{
      display: unset ;
    }
    
    .btn-primary.active, .custom-icon-button.default{
      height: 32px;
      // width: 41px;
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 0px !important;
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
    
  }
}
