@import '../../styles/variables';

.signup-page {
  display: grid;
  grid-template-columns: 50% 50%;
  background-color: $white;
  height: 100vh;
  .img {
    text-align: center;
    margin: auto;
    img {
      max-width: 400px;
      text-align: center;
    }
  }
  .signup-content {
    align-items: center;
    max-width: 350px;
    margin: auto 0px;
  }
  .signup-form {
    box-shadow: $box-shadow-sm;
    border-radius: 2px;
    margin-bottom: 20px;
    padding: 20px 20px;
  }
  .input-group {
    margin-top: 20px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    column-gap: 10px;
  }
  .signup-button {
    width: 100%;
    margin-top: 20px;
  }
  .wellcome-text {
    text-align: center;
    padding: 20px 0px;
  }
  .logo {
    max-width: 200px;
    margin-left: auto;
  }
  .custom-avatar-wrapper {
    margin: 0 auto;
  }
  .avatar-overlay {
    z-index: 1;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    .img {
      max-width: 80%;
    }
    .signup-content {
      margin: 0px auto;
      margin-top: 40px;
    }
  }
}
