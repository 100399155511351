@import "../../../styles/variables";

.file-upload-modal {
  .dropzone {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $whisper;
    color: $trout;
    font-size: $font-size-large;
    margin: 20px 0px;
    &.drag-active {
      border: 1px dashed $tundora;
      &.drag-accept {
        border: 1px dashed $fruit-salad;
      }
      &.drag-reject {
        border: 1px dashed $persian-red;
      }
    }
  }
  .upload-icon {
    font-size: 128px;
    color: rgba($color: $trout, $alpha: 0.1);
  }
  .message {
    color: rgba($color: $trout, $alpha: 0.5);
    margin: 2px 0px;
  }
  .upload-button {
    padding: 5px 15px;
    font-size: $font-size-medium;
  }
  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .scrollable-files-list {
    height: 100px;
    margin: 10px 0px;
  }
  .download-template-actions {
    text-align: right;
    a {
      display: inline;
    }
  }
}