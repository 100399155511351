@keyframes rotate { // spinner animation: ROTATE
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Styles for the spinner container
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 2.7em;

  // Styles for the spinner
  .spinner {
    border: 3px solid #ccc;
    border-top: 3px solid #eb672d;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: rotate 0.5s linear infinite;
    margin: 0.5em;
  }
}

.spinner-container-full {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;

  // Styles for the spinner
  .spinner {
    border: 6px solid #ccc;
    border-top: 6px solid #eb672d;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: rotate 0.5s linear infinite;
  }
}

