
@import "../../../styles/variables";

.custom-video-player {
  padding-top: 56.25%;
  position: relative;
  .react-player {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }
}