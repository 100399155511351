@import '../../styles/variables';

.login-view {
  display: flex;
  flex-direction: row;
  background-color: $white;
  height: 100vh;
  & > div:first-child {
    width: 35%;
    position: relative;
    & > div {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 10px;
    }
  }
  & > div:not(:first-child) {
    width: 75%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .text-heading {
    color: $trout;
  }
  .login-form {
    text-align: center;
    & > * > * {
      margin-bottom: 30px;
    }
    img {
      max-width: 250px;
    }
    .wellcome-text {
      font-size: 20px;
      color: #6f6f6f;
    }
  }
  .login-button {
    width: 200px;
    max-width: 90%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & > * {
      width: 100% !important;
    }
    & > div:first-child {
      height: 40vh;
      & > div {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
      }
    }
    & > div:not(:first-child) {
      height: 60vh;
    }
  }
}
