@import "../../../styles/variables";

$custom-Drawer-width: 500px;
$custom-Drawer-color: #FFFFFF;
$custom-Drawer-border-shadow: -2px 0px 4px rgba(0, 0, 0, 0.24);
$custom-Drawer-color-active: 'red';

.custom-custom-Drawer {
  width: $custom-Drawer-width;
  border-right: 0;
  color: "#273137";
  right: 0;
  top: 54px;
  bottom: 0px;
  background-color: $custom-Drawer-color;
  transition: width 1000ms ease-in-out;
  -webkit-transition: width 1000ms ease-in-out;
  overflow-y: auto;
  box-shadow: $custom-Drawer-border-shadow;
  z-index: 9999;
  position: fixed;

  .category-active {
    path {
      fill: #273137
    }
  }

  .tooltip-drawer {
    float: left;

  }

  &__header {
    min-height: 57px;
    border-bottom: 1px solid #ceced1;
    display: flex;
    align-items: center;
    padding: 14px 16px;

    &__icon {
      margin-left: auto;
      cursor: pointer;
      height: 22px;

      &:hover {
        border-radius: 4px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #273137;
      }

      &--copy {
        cursor: copy;
      }

      &--not-allow {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    &-seond {
      display: block;
    }

  }

  &__time {
    padding-left: 16px;
    width: 100%;
    position: relative;

    &__parent {
      display: flex;
    }

    &__line {
      height: 32px;
      position: absolute;
      right: -16px;
      width: 1px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #C9D1CF;
      top: -4px;
    }

    &__text {
      font-size: 10px;
      line-height: 12px;
      color: #a9a7af;

    }

    &__text-sub {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: #a5a4af;
      margin-left: 4px;
      // text-transform: uppercase;
    }

    .pointer-cursor {
      cursor: pointer;
    }
  }

  &__title {
    display: flex;
    min-height: 160px;
    align-items: center;
    padding-left: 46px;
    padding-right: 46px;
    border-bottom: 1px solid #ceced1;

    &__parent {
      margin-left: 24px;
    }

    &__text {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #9f9eaa;
    }

    &__text-sub {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: #273137;
      margin-top: 2px;
      margin-bottom: 2px;
      text-transform: uppercase;
    }

    &__text-link {
      font-size: 12px;
      line-height: 14px;
      color: $orange;
      cursor: pointer;

      svg {
        height: 9px;
        margin-left: 5px;
      }

      &-second {
        margin-left: auto;
        font-weight: normal;
      }
    }
  }

  &__groupIcon {
    margin-left: 80px;
    display: flex;
    align-items: center;
  }

  .custom-Drawer-content {
    padding-top: 20px;
    overflow: hidden;

    &__container {
      display: flex;

      button {
        height: 30px;
      }
    }

    &__title {
      padding-left: 62px;
      padding-right: 62px;
      padding-bottom: 24px;
      font-size: 18px;
      line-height: 24px;
      color: #a5a4af;
    }

    &__item {
      padding-top: 16px;
      cursor: pointer;
      padding-bottom: 16px;

      &-status {
        margin-left: auto;
        font-weight: bold;
        margin-right: 16px;
        min-width: 78px;
      }

      &-text {
        padding-left: 62px;
        padding-right: 62px;
        display: flex;
        font-size: 14px;
        line-height: 16px;
        color: #273137;
        align-items: center;
        transition: all 200ms ease-in-out;
        -webkit-transition: all 200ms ease-in-out;

        &-second {
          padding-left: 41px;
          padding-right: 41px;

          &__icon {
            height: 12px;
            margin-right: 18px;
            margin-left: unset !important;
          }

          &__icon.active {
            transform: rotate(90deg);
            transition: transform 200ms ease-in-out;
            -webkit-transition: transform 200ms ease-in-out;
          }

          &__parent {
            display: flex;
            align-items: center;
            width: 100%;
          }

          &__parent.active {

            font-weight: 600;
          }
        }

        svg {
          margin-left: auto;
          height: 10px;
        }
      }

      &:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #273137;
        transition: background 200ms ease-in-out;
        -webkit-transition: background 200ms ease-in-out;

        .custom-Drawer-content__item-text {
          padding-left: 74px;
          padding-right: 50px;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #273137;
          transition: all 200ms ease-in-out;
          -webkit-transition: all 200ms ease-in-out;
        }

        .custom-Drawer-content__item-text-second {
          padding-left: 41px;
          padding-right: 41px;
          font-weight: normal;
        }
      }
    }

    &__child {
      display: none;
      transition: all 200ms ease-in-out;
      -webkit-transition: all 200ms ease-in-out;
      font-size: 12px;
      line-height: 14px;

      &-active {
        display: block;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.97), rgba(255, 255, 255, 0.97)), #273137;
        box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.15);
        transition: all 200ms ease-in-out;
        -webkit-transition: all 200ms ease-in-out;

        .rebate-information {
          padding: 32px 33px;
        }
      }

      &__lable {
        font-size: 12px;
        line-height: 14px;
        color: #b0afb9;
        margin-top: 3px;
      }

      &__group {
        margin-top: 21px;

        &-second {
          svg {
            height: 20px;
            margin-right: 5px;
          }
        }
      }
    }

    .rebate-count {
      line-height: 16px;
      text-align: center;

      .rebate-count-text {
        font-weight: 600;
        font-size: 14px;
        color: #273137;
      }
    }
  }

  &:not(.toggled) {
    @media (max-width: 1024px) {
      position: fixed;
      margin-left: 0px;
      transition: margin 200ms;

      .logo,
      .small-logo {
        transition: none;
      }
    }
  }

  &.toggled {

    width: 0px;
    transition: all 1000ms ease-in-out;
    -webkit-transition: all 1000ms ease-in-out;


    .custom-Drawer-content {
      padding-top: $header-height;
      transition: padding-top 500ms ease-in-out;
      -webkit-transition: padding-top 500ms ease-in-out;

      .custom-Drawer-nav {
        a.custom-Drawer-link {
          padding: 0px 0px 0px 18px;
          transition: all 200ms;
          -webkit-transition: all 200ms;

          &>span {
            visibility: hidden;

            opacity: 0;
            font-size: 0em;
            transition: visibility 0.2s linear, opacity 0.2s linear, font-size 200ms;
          }

          .custom-Drawer-item-text {
            opacity: 0;
            transition: opacity 0.4s linear;
          }
        }
      }

      .custom-Drawer-brand {
        margin-right: 15px;
      }
    }


  }

  .custom-Drawer-toggle {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    outline: none;
    margin-right: 15px;
    transition: all 400ms;
  }

  @media (max-width: 767px) {
    top: 49px;
    width: 100%;

    &__title {
      display: flex;
      min-height: 160px;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
    }

    .custom-Drawer-content {
      &__title {
        padding-left: 32px;
        padding-right: 32px;

      }

      &__item {

        &-text {
          padding-left: 32px;
          padding-right: 32px;

          &-second {
            padding-left: 41px;
            padding-right: 41px;

            &__parent {
              flex-direction: column;
              align-items: unset;

              .custom-custom-Drawer__title__text-link-second {
                margin-left: unset;
                margin-top: 5px;
                float: left;
              }
            }
          }
        }

        &:hover {

          .custom-Drawer-content__item-text {
            padding-left: 46px;
            padding-right: 18px;

          }

          .custom-Drawer-content__item-text-second {

            padding-left: 41px;
            padding-right: 41px;

          }
        }
      }

      &__child__group-third {
        margin-top: 21px;
      }
    }

    &__header {
      &__icon {
        height: unset;
      }
    }

    &__groupIcon {
      margin-left: unset;

    }

    &__time {
      margin-top: 7px;
    }
  }

  .none-program-text { 
    text-align: center;
    color: $color-danger;
    font-size: $font-size-xlarge;
    line-height: $line-height-xlarge;
    padding-top: 30px;
  }
}

.rebate-container {
  padding: 0 !important;

  .rebate-row {
    color: #273137;
    min-height: 45px;
    border-top: 1px solid #C9D1CF;
    margin-left: 0px !important;
    margin-right: 0px !important;
    cursor: default;

    .rebate-name {
      font-weight: 600;
      font-size: 14px;
    }

    .rebate-expires {
      font-weight: 400;
      font-size: 12px;
      margin-right: 5px
    }

    .rebate-contribution {
      font-weight: 400;
      font-size: 12px;
    }

    .rebate-value {
      font-weight: 600;
      font-size: 20px;

      .rebate-value-sub {
        margin-left: 3px;
        font-size: 10px;
        font-weight: 400;
      }
    }

    .rebate-value-units {
      font-weight: 600;
      font-size: 12px;
    }

    .rebate-row-spacing {
      padding: 0.25rem 0;
      margin: 0 !important;
    }

    .center-text-div {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .center-all-text {
      align-items: center;
    }

    .left-all-text {
      align-items: start;
    }

    .hover-cursor {
      cursor: pointer;
      margin-left: 5px
    }

    &:last-child {
      border-bottom: 1px solid #C9D1CF;
    }
  }
}

.button-show-more-rebate {
  padding: 16px 0px;
  color: $orange;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
}