.searchInput{
  width: 120px;
  height: 32px;
  &-parent{
    position: relative;
  }
  &-inputValue{
    position: absolute;
    right: 10px;
    top: 6px
  }
  &-long {
    width: 500px;
    transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    
    .searchInput__option{
      width: 500px;
    }
  }
  &-short{
    width: 120px;
    transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    .searchInput__option{
      width: 500px;
    }
  }
  cursor: pointer;

  .css-g1d714-ValueContainer{
    padding: 0px 8px
  }
  &__option{
    padding: 8px 36px;
    padding-right: 16px;
    font-family: "proxima-nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #04031A;
    &:hover{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #273137;
      path {
        fill: #eb672d;
      }
    }
    &_subLable{
      font-family: "proxima-nova";
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: rgba(4, 3, 26, 0.5);
      margin-left: 8px;
    }
    &_text{
      display: flex;
      align-items: flex-end;
      width: 100%;
      & > span {
        display: block;
        max-width: calc(100% - 120px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &_icon{
      margin-left: auto;
    }
    &_search{
      color: #04031A;
      font-weight: 600;
      &_subLable{
        font-style: italic;
        color: rgba(4, 3, 26, 0.5);
      }
    }
  }
  .searchInput-inputValue{
    cursor: pointer;
    z-index: 1000000;
  }

  @media (max-width: 768px) {
     
      width: 32px;
      height: 32px;
      &-long {
        width:500px;
        transition: all 100ms ease-in-out;
        -webkit-transition: all 100ms ease-in-out;
        .searchInput__option{
          width: 500px;
        }
      }
      &-short{
        width: 32px;
        transition: all 100ms ease-in-out;
        -webkit-transition: all 100ms ease-in-out;
        .searchInput__option{
          width: 500px;
        }
        .searchInput__control{
          border-radius: 100px !important;
          padding: 7.5px;
          margin: auto;
         
          &_icon{
            height: 15px;
            width: 15px;
          }
        }
        .searchInput__input{
          width: 32px ;
          position: absolute;
          top: -24px;
          right: -8px;
          height: 32px;
          input{
            width: 32px !important;
            caret-color: transparent !important;
          }
         
        }
      }
      &__option{
        padding: 8px 8px;
        text-align: left;
        
        &_text{
        flex-direction: column;
        align-items: flex-start;
        min-width: 220px;
        }
        &_subLable{
          margin: unset;
        }
        &_icon{
          // display: none;
          height: 15px;
          width: 15px;
        }
      }
      &__placeholder{
        display: none;
      }
    
  }
  @media (max-width: 380px){
    &-long {
      width:220px;
      transition: all 100ms ease-in-out;
      -webkit-transition: all 100ms ease-in-out;
      .searchInput__option{
        width: 220px;
      }
   
    }
    &-short{
      width: 32px;
      transition: all 100ms ease-in-out;
      -webkit-transition: all 100ms ease-in-out;
      .searchInput__option{
        width: 220px;
      }
      
    }
  }
}
.scroll-section {
  max-width: 480px;
  width: 480px;
}

.title-under-line {
  border-bottom: 1px solid black;
  width: 480px;
  max-width: 480px;
}

.result-item {
  position: fixed;
  max-height: 80vh;
  margin-top: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;

  .footer-text {
    white-space: pre-line;
  }
}

.default-cursor {
  cursor: default;
}

.input-search-area {
  border: 1px solid #c9d1cf;
  padding: 0.5rem;
  margin: 0;
  border-radius: 4px;

  svg{
    width: 20px;
    height: 20px;
  }
  
  .input-search-text:focus {
    outline-width: 0;
  }

  .input-search-text {
    border: none
  }
}

.flex-all {
  flex: 1;
}

.input-search-area:hover {
  border: 1px solid #eb672d;
}

.pointer-cursor {
  cursor: pointer;
}

.result-row:hover{
  background-color: #f0f0f080;
}

.color-div {
  animation: skeleton-loading 1s linear infinite alternate;
  width: 100%;
  height: 20px;
  margin-bottom: 1rem
}

@keyframes skeleton-loading {
  0% {
      background-color: #f3f2f7; // hsl(200, 20%, 70%);
      border-radius: 10px;
  }

  100% {
      border-radius: 10px;
      background-color: #ffffffb6; // #f0f3f5; hsl(200, 20%, 90%);
  }
}
