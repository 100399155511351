
.topFilterTable {
  background: #FFFFFF;
  // box-shadow: inset 0px -1px 0px #E4E4E4;
  border-radius: 6px 6px 0px 0px;
  padding: 15px 32px;
  display: flex;
  align-items: center;
  &-second{
    &__search{
      margin-left: 10px;
       input {
        min-width: 230px;
      }
    }
  }
  &__dropDown{
    display: flex;
    align-items: center;
    .dropdown-toggle{
      margin-left: 15px;
      border: 1px solid #e4e4e4 !important;
      background: #FFFFFF !important;
      font-size: 16px;
      line-height: 20px;
      color: #273137 !important;
      &::after{
        display: none;
      }
     svg{
       margin-left: 20px;
     }
    }
    .dropdown-item:hover{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #273137 !important;
    }
    .dropdown-item{
      color: #273137 !important;
    }
  }
  &__group{
    margin-left: auto;
    display: flex;
  }
  &__button{
    margin-left: 16px;
  }
}
@media (max-width: 768px) {
  .topFilterTable {
    padding: 15px 20px;
    width: 700px;
    &__button{
      min-width:  160px !important;
    }
    .top-label-form-group.form-input input{
      min-width: 200px;
    }
    &__dropDown{
      margin-right: 50px;
    }
  }
  
}