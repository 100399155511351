// -- define variables -->
@import '../../../styles/variables';

.custom-empty-state {
  padding-top: 60px;

  .description-image,
  .description-message {
    margin-bottom: 30px;
  }
  .description-message {
    color: $text-secondary-color-light;
  }
  
  .description-title {
    margin-bottom: 15px; 
  }
  &-second{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: unset !important;
    min-height: 390px;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 24px;
    &__title{
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      color: #04031A;
      &-sub{
        margin-top: 12px;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
      }
    }
    &__link, &__link:hover{
      text-decoration-line: underline;
      font-size: 14px;
      line-height: 16px;
      color: $orange;
      
    }
    .description-message{
      margin-bottom: 162px;
    }
  }
}
