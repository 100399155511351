// -- Variables -->
@import "../../../styles/variables";

// -- SearchBar Component's style -->
.custom-search-bar {
  border: 1px solid $border-color-light;
  border-radius: 0.25rem;
  box-shadow: none;
  .input-group-prepend {
    margin: 0;
    .input-group-text {
      background-color: $white;
      border: none !important;
      color: $abbey;
    }
  }
  input.form-control {
    border: none !important;
    color: $abbey;
    padding-left: 0px;
    &:focus {
      outline-offset: 0px !important;
      box-shadow: none;
      border: none !important;
    }
  }
  .search-bar-icon {
    padding-right: 2px;
    cursor: pointer;
  }
}
