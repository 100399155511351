@import "../../../styles/variables";

$day-horizontal-margin: 0rem;
$row-height: 36px;

.custom-monthpicker,
.custom-datepicker,
.custom-weekpicker {
  .react-datepicker__week,
  .react-datepicker__month-wrapper {
    margin-bottom: 0.332rem;
    &:last-child {
      margin-bottom: 0.166rem;
    }
  }
  .react-datepicker__day-name, 
  .react-datepicker__day, 
  .react-datepicker__time-name {
    display: inline-block;
    width: $row-height;
    line-height: $row-height;
    text-align: center;
    margin: 0px $day-horizontal-margin;
  }

  .react-datepicker__day-names > * {
    color: $text-secondary-color;
    margin-top: 1rem;
  }

  .react-datepicker__triangle {
    &::before {
      border-bottom-color: $white;
    }
  }
  .react-datepicker {
    border-radius: 0px;
    box-shadow: $box-shadow-sm;
  }
  .react-datepicker__header {
    border-bottom: none;
    background-color: $white;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: unset;
  }
  .react-datepicker {
    // border-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .text-value-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .text-value {
    font-weight: 700;
    color: $color-light-blue;
    font-size: $font-size-medium;
  }
  .calendar-icon {
    margin-right: .5rem;
  }
  .btn.btn-link {
    padding: 0px;
  }
  .btn-link:hover,
  .btn-link:focus,
  .btn-link.focus,
  .btn-link.hover {
    text-decoration: none;
    color: $cerulean;
  }
  
  

  .hidden-input {
    display: none;
  }

  .react-datepicker__navigation,
  .react-datepicker__day {
    &:focus {
      outline: none;
    }
    &.react-datepicker__day--disabled {
      color: #ccc;
    }
  }

  .link-button-text {
    font-size: $font-size-small;
    &:active {
      font-weight: 900;
    }
  }

  .react-datepicker__day--selected, 
  .week_hightlight {
    color: $white !important;
    background-color: $color-blue;
    border-radius: 50%;
  }

  .week_hightlight {
    background-color: #bdedff;
    color: $text-primary-color !important;
    border-radius: 0px;
    &:first-child,
    &:last-child {
      // border-radius: 50%;
      color: $white !important;
      background-color: transparent;
      background-repeat: none;
      background-size: 100%;
    }
    &:last-child {
      // background-image: 
      // url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid meet' viewBox='0 0 100 100'>
      //   <rect x='0' y='0' width='50' height='100' fill='%23bdedff' />
      //   <circle cx='50' cy='50' r='50' fill='%231DB4CF' />
      // </svg>");
    }
    &:first-child {
      // background-image: 
      // url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid meet' viewBox='0 0 100 100'>
      //   <rect x='50' y='0' width='50' height='100' fill='%23bdedff' />
      //   <circle cx='50' cy='50' r='50' fill='%231DB4CF' />
      // </svg>");
    }
  }
  .react-datepicker-wrapper{
    width: 100%;
  }
  .date-input{
    background: #ffffff;
    border: 1px solid rgba(4, 3, 26, 0.1);
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #04031a;
    padding-left: 8px;
    height: 40px;
    width: 100%;
  }
  
  .date-input:not(.readOnly) {
    background-color: #fff;
  }
  .custom-input-icon {
    position: absolute;
    top: -1px;
    right: -4px;
    z-index: 1;
    height: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: inherit;
    cursor: pointer;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0px;
    color: $abbey;
    &:focus,
    &:hover,
    &:active {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
      color: $abbey;
    }
  }
  .datepicker-custom-header {
    margin: 0px 10px;
    display: flex;
    justify-content: space-between;
    .react-datepicker__navigation {
      position: unset;
      margin-top: 3px;
    }
    select {
      border: 1px solid $border-color-light;
    }
  }
}

.picker-navigator {
  display: inline-flex;
  align-items: stretch;
  font-size: inherit;
  color: $text-primary-color;
  .picker-nav-icon {
    font-size: 1.4rem;
  }
  .nav-btn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:active {
      box-shadow: none !important;
      border: 1px solid $border-color-light;
    }
    &.disabled {
      color: $text-secondary-color-light;
      pointer-events: none;
    }
  }

  .nav-center {
    width: 100px;
    margin: 0 10px;
  }

  .nav-left,
  .nav-right {
    width: 40px;
  }
  .nav-right {
    svg {
      transform: rotate(180deg);
    }
  }
}

@media (max-width: 1023px) {
  div.picker-navigator {
    .nav-left,
    .nav-right {
      width: 30px;
    }
    .nav-center {
      width: 85px;
      margin: 0 7px;
    }
  }
}

.custom-monthpicker {
  .react-datepicker__month-text {
    height: $row-height;
    line-height: $row-height;
    font-family: $font-family-base;
  }
}

.custom-monthpicker .react-datepicker__day--selected, .custom-monthpicker .week_hightlight, .custom-datepicker .react-datepicker__day--selected, .custom-datepicker .week_hightlight, .custom-weekpicker .react-datepicker__day--selected, .custom-weekpicker .week_hightlight{
  background: $orange;
}

.top-label-form-group.warning input{
  border: 1px solid #c19e3f;
  background:#fef9e9 !important;
  color: #c19e3f;
}