@import '../../../styles/variables';

.custom-tab-buttons {
  margin: 34px 48px;
  .tabs-nav {
    align-self: flex-end;
    flex-direction: row !important;

    &.vertical {
      flex-direction: column !important;
    }
    .nav-link{
      font-style: normal;
      font-weight: 600 ;
      font-size: 14px !important;
      line-height: 16px !important;
      color: rgba(4, 3, 26, 0.5) !important;
    }
    .textSub{
      color: rgba(4, 3, 26, 0.5);
      font-weight: 400;
    }
    .nav-item {
      &:focus,
      & .nav-link:focus {
        outline: none;
      }
      &:not(:first-child) {
        .flat:not(.active) {
          margin-left: 1px;
        }
      }
      .badge {
        position: absolute;
        border-radius: 50%;
        font-size: 10px;
        top: -5px;
        right: -5px;
        &.cycle {
          width: 15px;
          height: 15px;
        }
        &:not(.cycle) {
          border-radius: 3px;
        }
      }

      .nav-link {
        min-height: 40px;
        color: $text-primary-color;
        font-size: $font-size-small;
        display: inline-flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        &.flat {
          box-shadow: none;
          border-radius: 0px;
          background-color: $gallery;
          &.active {
            background-color: $white;
            color: $text-secondary-color;
            box-shadow: $box-shadow-sm;
          }
          
        }
        &.material {
          border-radius: 0px !important;
          border-bottom: 2px solid transparent;
          &.active {
            color: #04031A !important;
            background-color: unset;
            border-bottom: 2px solid $color-light-blue;
          }
        }
        .icon-wrapper {
          margin-right: 10px;
          width: 1rem;
          height: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      
      &:first-child .nav-link {
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
      }
      &:last-child .nav-link {
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }

      .badge {
        margin-left: 5px;
      }
    }

    &.vertical .nav-item .nav-link {
      width: 100%;
      border-bottom: 0;
      padding:unset;
      border-left: 2px solid rgba(4, 3, 26, 0.25);
      &.active {
        color: #04031A;
        background-color: unset;
        border-bottom: 0;
        border-left: 2px solid $orange;
      }
    }
  

  }
  @media (max-width: 768px) {
    margin: unset !important;
    .nav-link{
      display: none !important;
    }
    .nav-link.active{
      display: flex !important;
      justify-content: center;
      border-left: unset !important;
      padding: 16px 30px 16px 30px !important;
      height: unset !important;
      box-shadow: inset 0px -1px 0px rgba(74, 73, 86, 0.25);
      div{
        font-size: 26px;
        line-height: 32px;
        color: $slate !important;
        font-style: normal;
        font-weight: normal;
      }
    }
  }
}