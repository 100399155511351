@import "../../styles/variables";

.table-link-page{
  color: $orange;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg{
    margin-left: 5px;
    height: 12px;
    width: 12px;
  }

.tableView{
  background-color: #ffffff;

  }

  .table-link-page:hover{
    text-decoration: underline;
    text-underline-position: from-font
  }
  .table-groupIcon{
    display: flex;
    svg{
      margin-right: 9px;
    }
  }
  .table-time{
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }
  .tab-center{
    text-align: center;
    padding: unset;
  }
  .datatable__title--project, .datatable__td-project{
    text-align: center;
  }
  .table-tootip-float{
    float: left;
  }
  &-icon{
    path{
      fill: $slate;
    }
  }
  
  tr:hover {
    cursor: pointer;
  }
 
}