@import "../../../styles/variables";

.custom-text-body {
  margin-bottom: 0px;
  // color: inherit;
  font-size: inherit;
  // &.text-sm {
  //   font-size: $font-size-small;
  // }
  // &.text-md {
  //   font-size: $font-size-medium;
  // }
  // &.text-lg {
  //   font-size: $font-size-large;
  // }
  &.ellipsis-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}
.text-custom-tooltip{
  z-index: 1000000000000;
}

.text-custom-tooltip > .tooltip-inner {
  font-size: $font-size-small;
}

.text-loader-wrapper {
  margin-bottom: 0px;

  &.text-sm,
  &.text-sm .text-loader {
    height: $font-size-small;
    width: 100%;
  }

  &.text-md,
  &.text-md .text-loader {
    height: $font-size-medium;
    width: 100%;
  }

  &.text-lg,
  &.text-lg .text-loader {
    height: $font-size-large;
    width: 100%;
  }
}
