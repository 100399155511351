.username-dropdown{
  .line{
    background-color: linear-gradient(0deg, #C9D1CF, #C9D1CF),
    linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
    ;
    height: 1px;
    padding: unset;
    margin: unset;
    margin-bottom: 6px;
  }
  .dropdown-item{
    margin: unset;
    padding: unset;
    margin-bottom: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 40px;
    cursor: pointer;
  }
  .dropdown-item:hover, .dropdown-item:focus{
    background-color:  #f5f5f7!important;
  }

  .titleParent{
    margin-left: 8px;
  }
  
  .userName{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #04031A !important;
  }

  .subName{
    font-size: 14px;
    line-height: 16px;
    color: rgba(4, 3, 26, 0.5);
  }

  .subNameSecond{
    font-size: 16px;
    line-height: 20px;
    margin-left: 4px;
  }


  .dropdown-item.titleHeader, .dropdown-item.titleHeader {
    background-color: white !important;
    padding: 8px 16px;
    cursor: unset;
  }  
  .dropdown-menu{
    top: 52px;
    min-width: 260px;
    // min-height: 254px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
  .dropdown-item{
    display: flex;
    align-items: center;
  }
  .subNameSecondLogout:hover{
    color:  rgba(181, 14, 55, 1) !important;
    background-color: #ffff !important;
  }
}



