@import "../../../styles/variables";

$padding-space: .5rem;

.custom-expandable-input {
  border-radius: 5px;
  background-color: $white;
  padding: $padding-space;
  margin-bottom: 1rem;
  .label-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .input-label-heading {
      color: $text-secondary-color;
    }
  }
  .expand-box {
    position: relative;
    &::before {
      content: '';
      display: block;
      border-top: 1px solid $border-color-dark;
      width: 100%;
      height: 0px;
      margin: $padding-space 0px;
    }
    .edit-button {
      position: absolute;
      right: 0px;
      bottom: $padding-space;
      z-index: 9;
      display: block;
      cursor: pointer;
      font-size: 14px;
      &.to-save {
        color: $fruit-salad;
      }
      &.to-edit {
        color: $text-secondary-color;
      }
      &.loading {
        color: rgba($color: $fruit-salad, $alpha: .5)
      }
    }
    .textarea-autosize {
      padding-right: 25px;
      width: 100%;
      font-size: 14px;
      outline: none;
      box-shadow: none;
      display: block;
      overflow-y: hidden; // Removes scrollbar
      transition: height 0.2s ease;
      resize: none;
      border: none;
      color: $font-color-base;
      letter-spacing: .1em;
      line-height: 20px;
      &.show-scrollbar {
        overflow-y: scroll; // show scrollbar
      }
    }
  }
}

.expandable-input-loader {
  margin-bottom: 1rem;
  width: 100%;
  height: 36px;
  .input-loader {
    width: 100%;
    height: 36px;
  }
}
