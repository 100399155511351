.EstimateRebateValueModal {
    .modal-content {
        // height: 535px;


        .estimate-container {
            height: 420px;
        }

        .modal-header {
            border-bottom: unset;
            padding-right: 26px;
            padding-left: 26px;

            .modal-title {
                font-size: 20px;
                line-height: 28px;
                color: #000000
            }

            .close {
                font-size: 33px;
                font-weight: normal;
                // padding: unset;
                margin-left: auto;

                span {
                    color: #000000
                }
            }
        }

        .modal-body {
            display: flex;
            flex-direction: column;

            p {
                text-align: center;
                color: #000000;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }

            .title-step {
                font-size: 16px;
            }

            .estimate-form-wrapper {
                padding-right: 120px;
                padding-left: 100px;
                margin-top: 24px;
                display: flex;
                flex-wrap: wrap;

                justify-content: space-between;

                .top-label-form-group {
                    width: 45%;
                    margin-bottom: 18px;
                }

                .form-label {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                    color: rgba(4, 3, 26, 0.5);
                    margin-bottom: 8px;
                    display: flex;
                    align-items: center;
                }

                .custom-input {
                    width: 45%;

                    .form-group {
                        width: 100%;
                    }

                    .message {
                        position: relative;
                        padding-left: 15px;
                        font-weight: 300;
                        font-size: 14px;
                        color: rgba(4, 3, 26, 0.5);

                        &:before {
                            background: url(../../../assests/icons/danger.svg) no-repeat center center;
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 0;
                            display: block;
                            width: 12px;
                            height: 12px;
                            transform: translate(0, -50%);
                        }
                    }

                }

            }

            .footer-step-i {
                display: flex;
                justify-content: flex-end;
                padding-right: 15px;
            }

            .estimate-form-wrapper-step-ii {
                display: flex;
                justify-content: center;
                height: 300px;

                #scrollableDiv {
                    overflow: auto;
                    border: 1px solid rgba(4, 3, 26, 0.1);
                }

                .infinite-scroll-component__outerdiv {
                    max-height: 200px;
                    min-height: 200px;
                }

                .option-item {
                    padding: 10px 10px;
                    color: rgba(4, 3, 26, 0.5);

                    &:hover {
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #273137;
                        cursor: pointer;
                    }
                }

                .option-selected {
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #273137;
                }

                .form-label {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                    color: rgba(4, 3, 26, 0.5);
                    margin-bottom: 8px;
                    display: flex;
                    align-items: center;
                }

                .form-group {
                    width: 50%;
                    margin-top: 30px;
                }

                flex-grow: 1;

                .react-select-container>div {

                    &:hover,
                    &:active {
                        border-color: #e9e9ea !important;
                    }

                }

                .custom-input {
                    width: 45%;

                    .form-group {
                        width: 100%;
                    }

                    .message {
                        position: relative;
                        padding-left: 15px;
                        font-weight: 300;
                        font-size: 14px;
                        color: rgba(4, 3, 26, 0.5);

                        &:before {
                            background: url(../../../assests/icons/danger.svg) no-repeat center center;
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 0;
                            display: block;
                            width: 12px;
                            height: 12px;
                            transform: translate(0, -50%);
                        }
                    }
                }

                .no-options {
                    margin-top: 10px;
                    text-align: center;
                }

                .loading-options {
                    margin-top: 10px;
                }
            }

            .rebate-program {
                font-size: 20px;
                margin-bottom: 30px;
                margin-top: 20px;
            }

            .estimate-form-wrapper-step-iii {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                height: 240px;



                .total-estimate-value {
                    margin-bottom: 25px;
                }

                .total-value {
                    font-size: 50px;
                    margin-bottom: 25px;
                }

                .line {
                    width: 50%;
                    border-top: 1px solid #000000;
                }

                .estimate-infor {
                    p {
                        text-align: left;
                    }

                    width: 50%;
                    display: flex;
                    justify-content: space-between;
                    margin: 0 auto;
                    align-items: center;

                    .estimate-infor-product {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 16px;
                        color: #273137;
                        margin-bottom: 8px;
                        display: flex;
                        align-items: center;
                    }

                    .estimate-infor-price {
                        .estimate-infor-price-number {
                            font-weight: 600;
                            font-size: 20px;
                            color: #273137;
                        }

                        p {
                            text-align: center;
                        }

                        .rebate-unit {
                            color: #273137;
                            font-size: 14px;
                            font-weight: 600;
                        }

                    }

                    .rebate-contribution {
                        margin-left: 10px;
                    }
                }

                .custom-custom-Drawer__title__text-link-second {
                    margin-left: unset !important;
                    text-align: center;
                    margin-bottom: 20px;
                }



            }

            .footer-step-ii {
                display: flex;
                justify-content: flex-end;
                padding-right: 15px;
            }


        }
    }


}

@media (max-width: 1200px) {
    .EstimateRebateValueModal {
        .modal-content {
            .modal-body {
                .estimate-form-wrapper {
                    padding-right: 70px;
                    padding-left: 50px;
                }

                .estimate-form-wrapper-step-iii {
                    .line {
                        width: 70%;
                    }

                    .estimate-infor {
                        width: 70%;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .EstimateRebateValueModal {
        .modal-content {

            .modal-body {
                .estimate-form-wrapper {
                    padding-right: 10px;
                    padding-left: 10px;
                }

                .footer-step-i {
                    padding-right: 10px;
                }

                .footer-step-ii {
                    padding-right: 10px;
                }



                .estimate-form-wrapper-step-ii {
                    .custom-input {
                        width: 80%
                    }
                }

                .estimate-form-wrapper-step-iii {
                    .line {
                        width: 90%;
                    }

                    .estimate-infor {
                        width: 90%;
                    }

                    .estimate-infor>div {
                        padding-right: 10px;
                    }
                }


            }
        }
    }
}

@media (max-width: 459px) {
    .rebate-contribution {
        display: block;
        margin-left: 0px !important;
    }
}