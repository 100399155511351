@import '../../../styles/variables';

.pill-button {
  font-size: $font-size-small;
  padding-left: .5em;
  background-color: $charade;
  border: 1px solid $dusty-gray;
  color: $white;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  border-radius: 2px;

  .close-icon {
    cursor: pointer;
    align-self: stretch;
    padding: .2em .5em;
    margin-left: .2em;
    display: flex;
    align-items: center;
    &:hover {
      background-color: $dusty-gray;
    }
    svg {
      width: .75em;
      height: .75em;
    }
  }

  &,
  &.pill-button-sm {
    font-size: $font-size-small;
    height: calc(#{$font-size-small} * 2);
  }
  &.pill-button-md {
    font-size: $font-size-medium;
    height: calc(#{$font-size-medium} * 2);
  }
  &.pill-button-lg {
    font-size: $font-size-large;
    height: calc(#{$font-size-large} * 2);
  }
}
