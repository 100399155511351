// -- define variables -->
@import '../../../styles/variables';

$SIZE_SMALL: 24px;
$SIZE_MEDIUM: 40px;
$SIZE_LARGE: 56px;


.custom-avatar-wrapper,
.avatar-loader {
  &,
  & .custom-avatar,
  & .avatar-overlay,
  & .uploading-overlay {
    border-radius: 100px !important;
    border: 1px solid #C9D1CF;
    box-sizing: border-box;
    background-color: #eef4f7;
    cursor: pointer;
  }

  position: relative;
  cursor: pointer;
  &.sm {
    width: $SIZE_SMALL;
    height: $SIZE_SMALL;
  }
  &.md {
    width: $SIZE_MEDIUM;
    height: $SIZE_MEDIUM;
    border: 2px solid $border-color-light;
  }
  &.lg {
    width: $SIZE_LARGE;
    height: $SIZE_LARGE;
    border: 2px solid $border-color-light;
  }
  .custom-avatar {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    &.default-avatar {
      position: absolute;
      width: 50%;
      height: 50%;
      left: 0px;
      top: 0px;
      transform: translate(50%, 50%);
    }
  }
  .uploading-overlay {
    z-index: 7;
  }
  .avatar-overlay {
    z-index: 8;
    position: absolute;
    bottom: 2%;
    &.right {
      right: 2%;
    }
    &.left {
      left: 2%;
    }
    width: 30%;
    height: 30%;
    background-color: $border-color-dark;
    border: 1px solid $border-color-light;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .upload-icon {
    color: $border-color-light;
    font-size: $font-size-medium;
    cursor: pointer;
    &:active {
      color: $border-color-dark;
    }
  }
  &.no-border {
    border: none;
  }
}

.avatar-loader {
  border-radius: unset !important;
  border: none !important;
 
}

.custom-avatar-wrapper:hover,
.avatar-loader:hover {
  border: 1px solid #273137;
}
.custom-avatar-wrapper.md, .avatar-loader.md {
  border: 1px solid #C9D1CF
}

.custom-avatar-wrapper.lg,
.avatar-loader.lg {
  border: 1px solid #C9D1CF !important;
}
@media (max-width: 768px) {
  .custom-avatar-wrapper.sm, .avatar-loader.sm{
    height: 32px;
    width: 32px;
  }
}