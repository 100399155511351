.import-view{
  .react-datepicker{
    min-width: 380px;
  }
 &-second{
  justify-content: center;
  display: flex;
  height: 100vh;
  align-items: center;
 }
 &__progress{
   padding-left: 170px;
   padding-right: 170px;
   width: 100%;
   text-align: center;
 }
 &__successful{
   &__text{
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    margin-top: 24px;
    margin-bottom: 26px;
   }
 }
 &__buton{
   width: 100%;
   margin-top: 20px;
   text-align: right;
 }
 &__subTitle{
   margin-top: 30px;
 }
 .top-label-form-group.warning.form-input input {
  border: 1px solid #c19e3f;
  background: #fef9e9;
  color: #c19e3f;
  }

  .top-label-form-group.danger.form-input input {
    background: #f8e7eb;
    border: 1px solid #e8b3c1;
  }

  .datatable__title--keepChange {
    text-align: center;
  }
  .datatable__td-keepChange{
    .checkbox{
      justify-content: center;
    }
  }
  .Roles__form-group{
    margin: unset;
  }
  .datatable__title--keepChange, .datatable__title--NewConstruction, .datatable__title--ApplicationLink, .datatable__title--WebsiteLink,  .datatable__title--MaxAnnualIncentive, .datatable__title--programId ,  .datatable__title--MinCustomerContribution {
    white-space: break-spaces;
    text-align: center;
  }
  .datatable__table tbody tr:hover{
    mix-blend-mode: normal
  }
  &__table{
    &__error{
      margin-bottom: 20px;
      text-align: left;
    }
  }
}
@media (max-width: 768px){
  .import-view{
    &__progress{
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.import-view-rebates{
  .datatable__table {
    table-layout: unset;
    min-width: 4500px !important;
    .datatable__title--Notes{
      min-width: 100px;
    }
    .datatable__title--keepChange{
      max-width: 200px;
    }
  }
}