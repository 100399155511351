// -- Variables -->
@import "../../../styles/variables";

.DropdownMobile {
  font-size: inherit;
  &_blur{
    outline: none !important;
  }
  
  &_chevron{
    display: none;
  }
  &_dropdown{
    display: none;
  }
  @media (max-width: 768px) {
    &_dropdown {
      display: unset;
      position: absolute;
      top: 53px;
      width: 100%;
      left: 0;
      right: 0;
      background: #FFFFFF;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
      &-close{
        display: none !important;
        
        transition: all 400ms ease-in-out;
        -webkit-transition: all 400ms ease-in-out;
      }
    }
    
   
    &_item{
      display: flex;
      align-items: center;
      padding: 16px 16px 16px 48px;
  
      cursor: pointer;
      background: #FFFFFF;
      box-shadow: inset 0px -1px 0px rgba(74, 73, 86, 0.25);
      
      &_text{
        margin-left: 10px;
        font-size: 16px;
        line-height: 20px;
        color: #273137;
      }
      svg{
        height: 15px;
        width: 15px;
      }
      &_icon{
        margin-left: auto;
      }
    }
    &_item.active{
      background: $slate ;
      box-shadow: inset 0px -1px 0px rgba(74, 73, 86, 0.25) ;
      path{
        fill: #fff
      }
      .DropdownMobile_item_text{
        color: #fff;
      }
    }
    &_item.disable{
      background: #FFFFFF;
      box-shadow: none;
      path{
        fill: #cfcfd5
      }
      .DropdownMobile_item_text-sub{
        color: #cfcfd5;
      }
      .DropdownMobile_item_text{
        color: rgba(4, 3, 26, 0.5);
      }
    }
    &_parent{
      display: flex;
    }
    &_chevron{
      display: unset;
      width: 24px;
      height: 32px;
      border: 1px solid #9f9faa;
      border-top-right-radius: 4px ;
      border-bottom-right-radius: 4px ;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    &_chevron.active{
      border: 1px solid #273137;
      background: #273137;
      path{
        fill: #fff
      }
    }
   
    
  }
}
