
.custom-multi-select {
  .selected-items-wrapper {
    margin: 5px 0;
    display: inline-flex;
    flex-wrap: wrap;
    & > .selected-items-pill {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}