@import "../../../styles/variables";

.textarea-wrapper {
  width: 100%;
}
.custom-textarea {
  &.textarea-autosize {
    width: 100%;
    padding: 1rem !important;
    font-size: $font-size-medium;
    outline: none;
    box-shadow: none;
    display: block;
    transition: height 0.2s ease;
    resize: none;
    border: 1px solid $border-color-light;
    color: $text-primary-color;
  }
}
