@import '../../../styles/variables.scss';

.footer-step-ii {
  .custom-button {
    &.btn-md {
      background-color: transparent;
    }
  }
}

.custom-button {
  position: relative;
  cursor: pointer;

  .button-badge {
    position: absolute;
    transform: translate(50%, -50%);
    right: 0;
    top: 0;
  }

  &.btn {
    border-radius: 4px;
    font-weight: bold;
  }

  &.btn-md {
    // min-width: 120px;
    height: 40px;
    // background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #C9D1CF;
    border-radius: 4px;
    border: unset;
    color: #C9D1CF;
  }

  &.btn-sm {

    background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #C9D1CF;
    border-radius: 4px;
    border: unset;
    color: #C9D1CF;

  }

  &.noPadding {
    padding: 0 !important;
  }

  &::focus,
  &::active,
  &::hover {
    box-shadow: none !important;
    outline: none !important;
  }

  font-size: $font-size-medium;

  .btn-content-wrapper {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    &>* {
      margin-left: 4px;
      margin-right: 4px;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.custom-button.second {
  background: $orange !important;
  border-radius: 4px;
  color: #FFFFFF !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.custom-button.next {
  background: $slate !important;
  border-radius: 4px;
  color: #FFFFFF !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.custom-button.back {
  background: #FFFFFF !important;
  color: rgba(4, 3, 26, 0.5) !important;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  margin-right: 10px;
}