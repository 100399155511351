// -- Variables -->
@import "../../../styles/variables";

.card-item {
  height: 248px;
  padding: 24px;
  padding-bottom: 0;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  
  .card-header {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
  }
  .state {
    border-bottom: 1px solid rgba($color: #c9d1cf, $alpha: 0.8);
    color: rgba($color: #273137, $alpha: 0.3);
    font-style: normal;
    text-transform: uppercase;
    font-weight: 600;
    font-size: $font-12;
    margin-top: 29px;
    padding-bottom: 5px;
  }
  .card-body {
    padding: 0;
    .title {
      font-style: normal;
      font-weight: $font-weight-bold;
      font-size: $font-18;
      line-height: 20px;
      color: $color-primary;
      margin-top: 12px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    .sub-title {
      font-style: normal;
      font-weight: normal;
      font-size: $font-14;
      line-height: 16px;
      margin: 4px 0px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
   
  }
  .card-footer {
    background-color: transparent;
    padding: 0;
    min-height: 72px;
    & > svg {
      margin: 15px 10px;
    }
    & > svg:first-child {
      margin-left: 0px;
    }
  }

  @media all and (max-width: 768px) {
    height: 250px;
    .card-footer {
      min-height: unset;
      & > svg {
        margin: 10px 10px;
      }
    }
   
  }
  @media all and (max-width: 480px) {
    height: 350px;
    .card-footer {
      min-height: unset
    }
   
  }
}

.apply-link {
  color: $color-secondary;
  padding-right: 5.5px;
  font-style: normal;
  font-weight: normal;
  font-size: $font-12;
  line-height: 14px;
  cursor: pointer;
  .svg {
    margin-left: 5px;
  }
}
