@import "../../../styles/variables";

.custom-accordion {
  .accordion > .card {
    border: 0px;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $border-color-light;
    &:last-child {
      border-bottom: none;
    }

    &:not(:first-of-type):not(:last-of-type) {
      border-bottom: 1px solid $border-color-light;
    }

    .accordion-header {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      padding: 0;
      cursor: pointer;
    }
    .accordion-body {
      padding: 0;
    }
  }
  
}
