@import "../../../styles/variables";

.wysiwyg-root {
  .wysiwyg-editor {
    min-height: 300px;
    padding: 0px 20px;
    border: 1px solid $border-color-light;
  }
  .rdw-link-modal {
    min-height: 245px;
  }
}