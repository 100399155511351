@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../styles/variables";

.pagination {
  background: #FFFFFF;
  border-radius: 0px 0px 4px 4px;
  padding: 18px 18px;
  margin: unset;
  &__col{
    margin: unset;
    padding: unset;
  }
  .pagination-info {
    padding-top: 10px;
  }
  .paginate-container {
    margin: 0;
    white-space: nowrap;
    text-align: right;
    display: flex;
    &__lable{
      padding: 9px 0px;
      font-size: 14px;
      line-height: 16px;
      color: rgba(4, 3, 26, 0.5);
    }
  }
  ul.paginate-buttons {
    margin: 2px 0;
    white-space: nowrap;
    -webkit-box-pack: end;
    justify-content: flex-end;
    display: -webkit-box;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: $border-radius;
    margin-left: auto;
  }
  .page-link {
    position: relative;
    display: block;
    padding: 4px 12px;
    line-height: 1.25;
    color: $text-secondary-color;
    background-color: $white;
    border: $border-width solid $border-color-light;
    border-radius: $border-radius;
    font-weight: 600;
    font-size: 15px;
    color: rgba(4, 3, 26, 0.5);
  }
  .page-item.disabled .page-link {
    color: $text-secondary-color;
    pointer-events: none;
    cursor: auto;
    background-color: $white;
    border-color: $border-color-light;
    opacity: 0.7;
  }
  .page-item.disabled{
    cursor: not-allowed;
  }
  .page-item.active .page-link {
    z-index: 3;
    color: $orange;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), $orange;
    border-color: $orange;
  }
  .page-item .page-link:hover {
    z-index: 3;
    color: $orange;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), $orange;
    border-color: $orange;
  }
  .page-item:not(:first-child) .page-link {
    margin-left: 8px;
  }
}

@media (max-width: 768px) {
  .pagination {
    width: 700px;
  }
}