.CountDown{
  min-width: 100px;
  background: #eb672d;
  border-radius: 4px;
  padding: 6px 11px;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  height: 29px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 56px;
    min-width: unset;
    &__text{
      display: none;
    }
  }
}