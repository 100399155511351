.utility{
  &__link{
    color: #000000;
    font-size: 14px;
    line-height: 16px;
    &:hover{
      color: #000000;
    }
  }

  &__counties-name-column { 
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: default;
  }

}

.counties-tooltip-context{
  display: flex;
  flex-flow: column;
  text-align: left;
}