@import "../../../styles/variables";

.Switches-wrapper{
  width: 100%;
  .custom-control-label{
    font-size: 16px;
    line-height: 20px;
    color: #04031A;
  }
  .custom-control-input:checked ~ .custom-control-label::before{
    border-color: $orange;
    background-color: $orange;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: unset;
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $orange;
  }
  .custom-switch .custom-control-label::after{
    width: 8.5px;
    height: 8.5px;
    top: 6px
  }
  .custom-switch .custom-control-label::before{
    width: 23px;
    height: 13px;
  }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(10px);
  }
}

