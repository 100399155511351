.custom-progress {
  mix-blend-mode: multiply;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #eb672d;
  background: #fff;
  height: 30px;
  .progress-bar{
    background-color: #eb672d ;
  }
  &__text{
    margin-top: 12px;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
  }
}