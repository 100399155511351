$datatable-footer-height: 0px;
$color-white: #ffffff;
$sidebar-width: 200px;
$color-black: #eb672d;

.datatable {
  padding-bottom: $datatable-footer-height;
  overflow-x: auto;
  background-color: #FFFFFF;

  table{
    border-top: 1px solid #e4e4e4;
  }
  &__header,
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__tr{
    &__hidden{
      display: none !important;
      // transition: all 500ms ;
      // -webkit-transition: all 500ms ;
    }
    // &__show{
    //   margin-left: 48px;
    // }
  
  }

  &__header {
    align-items: flex-end;
    background-color: $color-white;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  &__footer {
    position: fixed;
    left: $sidebar-width;
    right: 0;
    bottom: 0;
    z-index: 100;
    height: $datatable-footer-height;
    background-color: $color-white;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    padding-left: 20px;
    padding-right: 20px;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 0 19px 38px rgba(0,0,0,0.7), 0 15px 12px rgba(0,0,0,0.5);
    }

    &-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
    }
  }

  &__td{
    &__parent{
      display: flex;
      align-items: center;
      &__icon{
        margin-right: 12px;
        height: 10px;
        width: 13px;
        &-open{
          transform: rotate(90deg);
        }
      }
      &__second{
        margin-left: 20px !important;
      }
    }
  }

  &__action {
    display: flex;
    align-items: center;

    .form__label {
      min-width: 0;
      margin-right: 5px;
    }

    .select {
      margin-right: 5px;
    }
  }

  &__more {
    margin-left: 20px;
    margin-right: 20px;
  }

  &__counter {
    span {
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__table {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    // border-spacing: 0 5px;

    &--loading {
      tbody tr {
        opacity: 0;
       
        $items-per-table: 20;
        @for $i from 1 through $items-per-table {
          &:nth-child(#{$i}) {
            animation-name: table-tr;
            animation-duration: 0.35s;
            animation-delay: #{$i * 0.06}s;
            animation-fill-mode: forwards;
          }
        }
      }
    }

    thead {
      th {
        box-shadow: inset 0px -1px 0px #e4e4e4;
        text-align: left;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 16px;
        padding-right: 16px;
        width: auto;
        white-space: nowrap;
         
        div {
          color: #9B9B9B;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
        }
        &:first-child {
          padding-left: 20px;
        }
      }
    }

    tbody {
      tr {
        // background-color: $color-white;
        // transition: background-color 0.35s;

        &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #273137;
          mix-blend-mode: multiply; 
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
          
        }
      }

      td {
        width: 1%;
        word-wrap: break-word;
        padding-left: 5px;
        padding-right: 5px;
        padding: 8px 16px;
        box-shadow: inset 0px -1px 0px #e4e4e4;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        &:first-child {
          padding-left: 20px;
        
        }
        // &:last-child {
        //   // border-radius: 0 7px 7px 0;
        // }
      }
    }

    // &__title {

    // }

    .checkbox {
      display: flex;
    }
  }

  &__nodata {
    text-align: center;
    padding: 40px 0 !important;
    background-color: #ffffff !important;
  }
  &__loading{
    position: relative;
  }

  &__title {
    > * {
      display: inline-block;
      cursor: pointer;
      margin: 0;
    }
  }

  &__select {
    width: 60px !important;
  }

  
  &__sort {
    margin-left: 11px;
    mask-image: url('./../../../assests/icons/sort_desc.svg');
    -webkit-mask-image: url('./../../../assests/icons/sort_desc.svg');
    mask-repeat: no-repeat;
    background-color: #9f9faa;
    width: 10px;
    height: 5px;
    transition: transform 0.35s, background-color 0.35s;

    &--asc {
      mask-image: url('./../../../assests/icons/sort_asc.svg');
      -webkit-mask-image: url("./../../../assests/icons/sort_asc.svg");
      transform: rotate(-180deg);
      background-color: $color-black;
    }

    &--desc {
      background-color: $color-black;
    }
  }

  &__link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}



@keyframes table-tr {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 1200px) {
  .datatable {
    &__footer {
      left: 0;
    }
  }
}

.datatable__table thead th.datatable__active div{
  color: #eb672d !important;
}

@media (max-width: 1025px) {
  .datatable__table {
    table-layout: unset;
    min-width: 900px;
  }
  .datatable__td-stt{
    width: 1% !important;
  }
 .table-link-page {
   width: 230px;
 }
 .datatable__td-start , .datatable__td-end, .table-time{
   width: 100px;
 }
}