@import "../../../styles/variables";
  .Dropdown-wrapper{
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: rgba(4, 3, 26, 0.5);
    height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 6px 8px;
    &.active{
      border: 1px solid #273137;
      color: #273137;
      border-radius: 4px;
      .Dropdown-wrapper__icon{
        path{
          fill: #273137;

        }
      }
    }
    
    &__scroll{
      max-height: 400px;
      overflow-y: auto;
      &-second{
        max-height: 300px;
      }
    }

    &-parent{
      position: relative;
      outline: none;
    }
    
    &_item{
      background: #FFFFFF;
      color: #04031A;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      padding: 11px 11px  11px 19px;
      cursor: pointer;
      &:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #273137;
      }
      &_text{
        width: 100%;
        display: flex;
        align-items: center;
      }
      &_subText{
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: rgba(4, 3, 26, 0.5);
        margin-left: 5px;
      }
      &_hoverText{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
      }
      &__history-text{
        max-width: 80%;
        line-break: anywhere;
      }
      &__icon{
        margin-left: auto;
        width: 19px;
        min-width: 19px;
        height: 16px;
        min-height: 16px;
        &-2{
          margin-right: 5px;
        }
      }
    }

    &__back{
      padding: 8px 16px 8px 19px;
      display: flex;
      align-items: center;
      svg{
        margin-right: 16px;
        
      }
      &-text{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #9f9faa;
        letter-spacing: 1.25px;
        font-variant: small-caps;
        text-transform: uppercase;
        line-break: anywhere;
      }
      &:hover{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #273137;
      }
    }

    &-search{
      border-bottom: 1px solid #eaeded;
      position: relative;
      input{
        width: 100%;
        border: unset;
        outline: none !important;
        box-shadow: none !important;
        padding: 9px 30px 9px 20px;
        caret-color: $orange;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #04031A;
        &::placeholder{
          font-size: 16px;
          line-height: 20px;
          font-style: normal;
          font-weight: normal;
          color: #b1b5b4
        }
      }
      &__icon{
        position: absolute;
        top: 10px;
        right: 11px
      }
    }

    &__icon{
      margin-left: 13px;
    }
    &:hover{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #273137;
      border-radius: 4px;
      outline: none;
      color: #273137;
      .Dropdown-wrapper__icon{
      
        path{
          fill: #273137;

        }
      }
    }
    &__active{
      border-radius: 4px;
      background: #273137;
      color: #FFFFFF !important;
      .Dropdown-wrapper__icon{
        path{
          fill: #FFFFFF !important;
        }
      }
      &__icon{
        path{
          fill: #FFFFFF !important;
        }
      }
      &:hover{
        opacity: 0.8;
        background: #273137;
        color: #FFFFFF;
        .Dropdown-wrapper__icon{
          path{
            fill: #FFFFFF !important; 
          }
        }
      }
    }
    &_dropdown{
      padding-top: 4px;
      display: unset;
      position: absolute;
      top: 49px;
      width: 100%;
      left: 0;
      right: 0;
      background: #FFFFFF;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      min-width: 300px;
      z-index: 10000000;
      &__clearFilter{
        display: none;
      }
      &-close{
        display: none ;
        transition: all 400ms ease-in-out;
        -webkit-transition: all 400ms ease-in-out;
      }
      &__parent{
        padding: 8px 16px 8px 19px;
        display: flex;
        align-items: center;
        border-top: 1px solid #eaeded;
        border-bottom: 1px solid #eaeded;
      }
      &__reset{
        font-size: 12px;
        line-height: 16px;
        color: #C9D1CF;
        cursor: pointer;
      }
      &__reset.active{
        text-decoration-line: underline;
        color: $orange;
      }
      &__total{
        font-size: 12px;
        line-height: 14px;
        color: #b1b5b4;
        margin-left: auto;
      }
      
      .card-footer{
        text-align: center;
        cursor: default;

        .footer-text {
          white-space: pre-line;
          font-size: 14px;
        }
      }
    }

    @media (max-width: 768px) {
      &-parent{
        position: unset;
      }
      &_dropdown{
        width: 100%;
        top: 53px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        padding-top: unset;
        &__clearFilter{
          padding: 21px 10px 21px 16px;
          display: flex;
          align-items: center;
          background: #273137;
          color: #FFFFFF;
          font-size: 16px;
          line-height: 20px;
          &__icon{
            margin-right: 8px;
            path{
              fill: white
            }
            &-2{
              margin-right: unset;
              margin-left: 8px;
            }
          }
          &_item{
            margin-left: auto
          }
          &__parent{
            display:flex;
            align-items: center;
          }
        }

        .card-footer{
          text-align: center;
          cursor: default;
          
          .footer-text {
            white-space: pre-line;
            font-size: 14px;
          }
        }
      }
      &-search__icon-2{
        display: none !important;
      }
    }
  }

  
