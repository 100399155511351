.InitialVisitMessage{
  position: fixed;
  background: #273137;
  mix-blend-mode: darken;
  opacity: 0.5;
  height: calc(100% - 50px);
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  &__icon{
    margin-right: 8px;
    margin-top: 8px;
  }
  &__parent{
    background-color: #FFFFFF;
    border: 1px solid rgba(4, 3, 26, 0.05);
    box-sizing: border-box;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    height: 88px;
    margin-top: 80px;
    display: flex;
    z-index: 100;
    text-align: left;
    z-index: 10000;
    position: absolute;
    left: 50%;
    margin-left: -200px;
    padding-left: 8px;
    padding-right: 8px;
  }
  &__icon{
  
    height: 32px;
    width: 32px;
  }
  &__title{
    color: #04031A;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding: 8px;
  }
  &__message{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(4, 3, 26, 0.5);
    max-width: 260px;
  }
  &__button{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #eb672d;
    border-left: 1px solid #C9D1CF ;
    padding-left: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    width: 90px;
  }

  @media (max-width: 378px) {
    &__icon{
      display: none;
    }
    &__button{
      width: 75px;
    }
    &__parent{
      margin-left: -170px;
    }
    &__message{
      max-width: 230px;
    }
  }
}