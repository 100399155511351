@import "../../../styles/variables";
.custom-header {
  width: 100%;
  z-index: 10000;
  position: fixed;
  height: $header-height;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $text-primary-color;
  padding: 0px;
  font-size: $default-font-size;
  background-color: $color-white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  &-item{
    margin-left: 24px;
  
  }
  &-item-second{
    margin-right: 24px;
    .DropdownMobile_parent.active{
      svg{
        rect:nth-child(2), rect:nth-child(0){
          fill: $slate ;
        }
        path {
          fill: white !important
        }
        
      }
    }
  }
  .questionParent{
    margin: unset !important;
    padding: unset !important;
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .iconHover:hover{
    cursor: pointer;
    path{
      fill: #273137;
    }
  }
  .iconHoverQuestion:hover{
    
    path:nth-child(1){
      fill: rgba(4, 3, 26, 0.05) ;
      stroke: #273137; ;
    }

  }
  
  .sidebar-toggle {
    & > * {
      font-size: 36px;
      cursor: pointer;
      &:hover {
        color: $cerulean;
      }
    }
  }
  
  .header-text {
    padding-left: 10px;
    font-size: 18px;
    cursor: pointer;
  }
  .header-nav {
    height: 100%;
  
    // @media (min-width: 1024px) {
    //   &:not(.toggled) {
    //     padding-left: 200px;
    //   }
    // }
   
    .nav-link, .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $text-primary-color !important;
        margin: 0px 10px;
        &:active, &:focus, &.active {
            color: $accent-color !important;
        }
    }
    .nav-avatar {
      padding-left: 0px;
    }
  }
  .dropdown.username-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      text-decoration: none;
      color: $text-primary-color;
      display: flex;
    }
    a.nav-link {
      margin: 0;
      padding: 0;
    }
    .dropdown-menu.show {
      font-size: unset;
      left: unset;
      right: 0px;
      .dropdown-item {
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
      }
      .dropdown-item.active {
        background-color: unset;
      }
    }
    
  }
  .header-back-button {
    cursor: pointer;
    & > *:first-child {
      margin-right: 10px;
    }
  }
  
  .nav-link{
    cursor: pointer;
  }
  &__CountDown{
    margin-right: 30px;
  }
  &__Reset{
    margin-left: 20px;
    margin-right: 20px;
    font-weight: normal;
    .custom-button.second{
      height: 29px;
    
    }
  }
  @media (max-width: 768px) {
    &-item{
      margin-left: unset;
      &-second{
        margin-right: 8px;
      }
    }
    &__Reset{
      margin-left: 16px;
      margin-right: 16px;
      
    }
    &__CountDown{
      margin-right: 10px;
    }
  }
}

@media (max-width: 380px){
  .hidden__small_phone{
    display: none !important;
  }
}