@import "../../../styles/variables";

@keyframes slideIn {
  from {
    right: -500px;
  }
  to {
    right: 0px;
  }
}

.help-modal {
  margin: 0;
  position: absolute;
  right: 0;
  top: 60px;
  animation-name: slideIn;
  animation-duration: 0.3s;
  max-width: 650px;
  .modal-body{
    padding: 24px 48px;
  }
  .modal-content{
    border-radius: 9px;
   
  }
  .modal-header{
    height: 81px;
    padding: 26px 50px;
    background: #273137;
    font-style: normal;
    align-items: center;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    .modal-title{
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      color: #fff;
    }
    .iconHelper{
      margin-right: 14px
    }
    .close{
      color: #fff !important;
      font-size: 33px;
      font-weight: normal;
      padding: unset;
      margin-left: auto;
    }
  
  }
  .helperTitle{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #273137;
    margin-bottom: 12px;
  }
  .helperDes{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: rgba(4, 3, 26, 0.5);
    margin-bottom: 24px;
  }
  .helperlink{
    color: $orange;
    text-decoration: underline;
    font-size: 16px;
    line-height: 20px;
    text-underline-position: from-font;

  }
  .helperParentLink{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    svg{
      margin-right: 9px;
    }
    .IconTargetLink{
      margin-left: 9px;
    }
  }
  .helperTextLink{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #273137;
  }
  .helperTextLink:hover{
    color: #eb672d;
    text-decoration: underline;
    text-underline-position: from-font;
  }
  .helperLine{
    background-color: rgba(201, 209, 207, 1)
    rgba(255, 255, 255, 0.5);
    height: 1px;
  }
  .helperFooter{
    display: flex;
    align-items: center;
    svg{
      margin-right: 20px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    svg:hover{
      opacity: 0.8;
    }
  }
  .connectWithUs{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(4, 3, 26, 0.5);
    max-width: 266px;
    margin-bottom: 14px;
  }
  @media (max-width: 768px) {
    .modal-header, .modal-body {
      padding: 10px;
    }
    .close{
      margin-right: 10px;
    }
  }
}
