
@import "../../../styles/variables";

.custom-modal {
  z-index: 1000000000;
  &.no-padding .modal-container {
    padding: 0px;

  }
  .modal-content-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .modal-loading-state {
    position: absolute;
    z-index: 9;
  }
  .close-button {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    border-radius: 30px;
    &:hover {
      background-color: $zorba;
      color: white;
    }
    z-index: 8;
  }
  .modal-container {
    z-index: 7;
  }
  .modal-body{
    padding: 24px 48px;
    color: #04031A;
    font-size: 16px;
    line-height: 20px;
  }
  .modal-content{
    border-radius: 9px;
    border: unset;
    &__line{
      background-color: rgba(201, 209, 207, 1)
      rgba(255, 255, 255, 0.5);
      height: 1;
    }
    &__button{
      margin-left: 16px;
    }
    &__group{
      float: right;
    }
  }
  .modal-header{
    // height: 80px;
    padding: 26px 50px;
    background: $slate;
    font-style: normal;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .modal-title{
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      color: #fff;
    }
    .iconHelper{
      margin-right: 14px
    }
    .close{
      color: #fff !important;
      font-size: 33px;
      font-weight: normal;
      padding: unset;
      margin-left: auto;
    }
  
  }
  
}

