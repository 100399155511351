@import '../../../styles/variables';

.custom-link-button {
  padding: 0px;
  &.btn-xs {
    font-size: $font-size-small;
  }
  &.btn-sm {
    font-size: $font-size-small;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  &.anchor-link {
    display: unset;
  }
  .link-button-text {
    padding-right: 0.2rem;
    color: $color-light-blue;
  }
}
