@import "../../../styles/variables";

.rating {
  margin: 0px;
  margin-top: 35px;
  margin-bottom: 10px;
  box-shadow: none;
  user-select: none;
  &.rangeslider-horizontal {
    height: 6px;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.1);
    .rangeslider__handle {
      width: 16px;
      height: 16px;
      border: none;
      outline: none;
      &::after {
        display: none;
      }
    }
  }
  .rangeslider__fill {
    border-radius: 0px;
    background-color: #1DB4CF;
    box-shadow: none;
  }
  .rangeslider__labels {
    position: absolute;
    top: -40px;
    .rangeslider__label-item {
      font-size: 13px;
    }
  }
}