@import "../../../styles/variables";

.top-label-form-group {
  position: relative;
  margin-bottom: 0px;
  &__search{
    position: relative;
    input{
      padding-left: 36px !important;
    }
  }
  &__icon{
    position: absolute;
    top: 11px;
    left: 10px;
  }
  .form-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(4, 3, 26, 0.5);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  .subLabel {
    margin-left: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  &.form-input {
    input {
      background: #ffffff;
      border: 1px solid rgba(4, 3, 26, 0.1);
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #04031a;
      padding-left: 8px;
      height: 40px;
    }
  }

  .form-control,
  .form-control-plaintext {
    width: 100%;
    color: $text-primary-color;
    border-radius: 0px;
    &:focus {
      outline: none;
      box-shadow: unset;
    }
  }

  .message {
    position: relative;
    padding-left: 15px;
    font-weight: $font-weight-light;
    font-size: $font-14;
    color: $color-black-light;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 12px;
      height: 12px;
      transform: translate(0, -50%);
    }
  }

  &.success {
    &.form-input {
      input {
        border: 1px solid $color-success;
      }
    }
    .message {
      &::before {
        background: url(../../../assests/icons/success.svg) no-repeat center center;
      }
    }
  }

  &.warning {
    &.form-input {
      input {
        border: 1px solid $color-warning;
      }
    }
    .message {
      &::before {
        background: url(../../../assests/icons/warning.svg) no-repeat center center;
      }
    }
  }

  &.danger {
    &.form-input {
      input {
        border: 1px solid $color-danger;
      }
    }
    .message {
      &::before {
        background: url(../../../assests/icons/danger.svg) no-repeat center center;
      }
    }
  }
}

.top-label-form-group.readOnly{
  .form-control{
    background-color: #d8d8d8;
    cursor: not-allowed
  }
} 