.Roles{
  &__form-group{
    margin-top: 12px;
  }
  &__add{
    font-size: 12px;
    line-height: 14px;
    margin-top: 14px;
    color: #eb672d;
    font-weight: bold;
    cursor: pointer;
  }
  &__infinite{
    font-size: 20px;
  }
  &__contain{
    color: #000000;
    margin: 32px 24px;
    &__title{
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 24px;
    }
    &__subTitle{
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 6px;
      &-second{
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
      }
    }
    &__button{
      margin-left: auto;
    }
    &__delete{
      margin-left: 10px;
    }
    &__user{
      display: flex;
      align-items: center;
      &__name{
        margin-left: 10px;
      }
    }
    svg{
      cursor: pointer;
      min-width: 10px;
    }
  }
  .datatable{
    border-radius: 6px;
  }
}
@media (max-width: 768px) {
  .Roles{
    &__contain{
      margin: 10px 0px
    }
  }
}