@import "../../../styles/variables";

.status-text {
  padding: 0px 6px;
  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  border-radius: 4px;
  margin: 0px 0px;
  border-style: solid;
  border-width: 1px;
  font-size: $font-12;
  line-height: 20px;
  text-transform: capitalize;
  &.md{
    font-size:14px;
    line-height: 16px;
    padding: 4px 8px;
  }
  &.active {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      $green;
    color: $green;
    border-color:  $green;
  }
  &.closed {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      #B50E37;
    color: #B50E37;
    border-color: #B50E37;
  }
  &.upcoming {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      rgba(242, 190, 34, 1);
    color: rgba(242, 190, 34, 1);
    border-color: rgba(242, 190, 34, 1);
  }
}
