@import "../../../styles/variables";

.overflow-menu-backdrop {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}

.overflow-menu {
  position: absolute;
  height: calc(100% + 50px);
  width: 340px;
  background-color: white;
  z-index: 1;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  top: 0px;
  right: -340px;
  opacity: 0;
  padding: 15px 20px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  -ms-transition: all .3s ease;
  transition: all .3s ease;
  &.open {
    opacity: 1;
    right: 0px;
  }
  .close-icon {
    position: absolute;
    cursor: pointer;
    right: 0px;
    top: 0px;
    svg {
      width: 30px;
      height: 30px;
    }
    &:hover {
      .icon-cancel .c {
        stroke: red;
      }
    }
  }
  .advance-search-button {
    width: 100%;
    margin-bottom: 10px;
    font-size: $font-size-small;
    height: 40px;
    padding-top: 10px;
    cursor: pointer;
    border: 1px dashed #00aff0;
    color: #00aff0;
    &:hover {
      color: #fff;
      background-color: #00aff0;
    }
    svg {
      font-size: 18px;
    }
  }
  .header {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .menu-items {
    padding-top: 15px;
    user-select: none;
    cursor: pointer;
    .menu-item {
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid #F3F3F3;
      svg {
        margin-right: 10px;
      }
      &:hover {
        color: #00aff0;
        svg > g > g:not(#boxActions-delay):not(#icCalendarGrey) {
          stroke: #00aff0;
        }
        #boxActions-delay path, #icCalendarGrey, svg .c {
          fill: #00aff0;
        }
      }
      &.activities-selected{
        color: #EB3D2F;
        svg {
          width: 15px;
          height: 15px;
        }
      }
      .icon-status {
        width: 15px;
        height: 15px;
        &.completed .icon-complete {
          fill: $dove-gray;
        }
      }
    }
  }
  // TODO: need refactor
  .activity-detail {
    border-bottom: 2px solid #F3F3F3;
    padding-bottom: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    .activity-type {
      font-size: 13px;
      padding-bottom: 7px;
    }
    .activity-name {
      font-size: 15px;
      font-weight: 700;
    }
  }
}