.modalPremium{
 &__body{
  background: #FFFFFF;
  border-radius: 8px;
  color: #000000;
  padding: 40px 20px 20px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }
 &__payment-card {
  padding: 30px 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 25px;
 
 }
 &__submit{
   width: 100%;
   text-align: center;
   &__button{
     margin-top: 10px;
     min-width: 180px;
   }
 } 
 &__text{
   margin-bottom: 42px !important;
   line-height: 33px !important;
 }
 &__sub-text{
   margin-bottom: 35px;
   line-height: unset;
   font-size: 25px;
   &-second{
     font-size: 20px;
     line-height: 20px;
     li{
       margin-bottom: 10px;
     }
   }
   &-third{
    padding-left: 30px;
    max-width: 750px;
  }
   &-three{
     margin-bottom: 1rem;
     line-height: unset;
   }
 
 }
 &__actionText{
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: #666666;
  text-align: center;
  cursor: pointer;
 }
 &__icon{
   cursor: pointer;
   margin-top: 64px;
   margin-bottom: 40px;
 }
 &__question{
  font-size: 16px;
  line-height: 20px;
   margin-top: 20px;
 }
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}
.StripeElement {
  width: 100%;
  padding: 10px;
  background: #ffffff;
  border: 1px solid rgba(4, 3, 26, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04031a;
  height: 40px;
}

.submit-button {
  margin-top: 30px;
  display: block;
}
.submit-button:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #7795f8;
  box-shadow: none;
}
.ErrorMessage {
  color: red;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
  svg {
      path:first-child {
          fill: red;
      }
      path:last-child {
          fill: #fff;
      }
      margin-right: 5px;
      margin-top: 2px;
  }
}
.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}
.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}
.ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}
.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}
.loading-box {
  height: 200px;
  margin-top: 10px;
  position: relative;
}
.payment-processed-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 100px;
  &>* {
      margin: 0px auto;
  }
  .custom-link-button {
      margin-top: 5px;
      font-size: 16px;
      .link-button-text {
          font-size: 16px;
      }
  }
}
.payment-processed-text {
  font-size: 18px;
}
.coupon-applied-text {
  font-size: 17px;
}