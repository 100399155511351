
.custom-tabs {
  
  .custom-tabs-header-second {
    width: 100%;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #E4E4E4;
    align-items: center;
    display: flex;
    justify-content: center;
  
    .nav-item{
      margin: 10px 16px;
      position: relative;
      :after{
        background-color: #04031a40;
        content: " ";
        position: absolute;
        right: -20px;
        top: 0px;
        width: 2px;
        height: 32px;
      }
      .non-line{
        &:after{
          display: none;
        }
      }
    
    }
    .nav-item .nav-link {
      padding: 6px 6px;
      border-radius: 8px !important;
      color: rgba(4, 3, 26, 0.5);
      font-weight: 600;
      font-size: 16px;
      box-shadow: unset !important;
    
    }
    .nav-pills .nav-link.active{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #eb672d;
      color: #eb672d;
    }
    
  }
  .tab-content {
    z-index: 8;
     padding: 14px 48px;
  }
  @media (max-width: 768px) {
    .custom-tabs-header-second {
     
     .tabs-nav{
      flex-wrap: unset;
      overflow: auto;
      
     }
    }
  }
}
