.rebates{
  .min_Customer{
    white-space: break-spaces
  }
  &__modal{
    .modal-body{
      padding: unset ;

    }
    .modal-content__group{
      padding: 0px 48px 14px 48px;
    }
    
    .create-value-button{ 
      width: fit-content;
    }
  }
}