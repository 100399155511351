
.ApplyRebateModal {
  color: #04031A;
  text-align: center;
  .custom-textarea{
    height: 180px !important;
    
  }
  .top-label-form-group.form-group{
    width: 100%;
  }
  .modal-body{
    padding: 24px 24px 37px 24px ;
  }
  .modal-content{
    border-radius: 9px;
   
  }
  .modal-header{
    height: 81px;
    padding: 26px 50px;
    background: #273137;
    font-style: normal;
    align-items: center;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    .modal-title{
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      color: #fff;
    }
    .iconHelper{
      margin-right: 14px
    }
    .close{
      color: #fff !important;
      font-size: 33px;
      font-weight: normal;
      padding: unset;
      margin-left: auto;
    }
  
  }
  .helperTitle{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #273137;
    margin-bottom: 12px;
  }
  .helperDes{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: rgba(4, 3, 26, 0.5);
    margin-bottom: 24px;
  }
  &__button{
    margin-top: 40px;
  }
 &__title{
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 16px;
  &-second{
    margin-top: 19px;
  }
 }
 &__sub-title{
  font-size: 16px;
  line-height: 20px;
  &__second{
    margin-top: 20px;
  }
 }
 &__link{
  font-size: 14px;
  margin-top: 30px;
  line-height: 16px;
  color: rgba(201, 209, 207, 1);
  cursor: pointer;
  &:hover{
    color: #eb672d;
    text-decoration: underline;
  }
  svg{
    margin-left: 2px;
    height: 13px;
    width: 13px;
  }
 }
 &__content{
   margin-top: 35px;
   margin-bottom: 35px;
   display: flex;
   align-items: center;
   &__or{
    color: #273137;
    min-width: 139px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
   }
 }
 &__upload{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.9);
  mix-blend-mode: multiply;
  border: 1px solid #C9D1CF;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 180px;
  flex-direction: column;
  align-items: center;
  &__title, &__icon{
    margin-top: 10px;
    &-second{
      margin-top: 20px;
    }
  }
  &__sub{
    margin-top: 50px;
  }
  &__icon--delete{
    margin-left: 5px;
  }
 }
}

@media all and (max-width: 768px) {
  .ApplyRebateModal {
  
    &__upload {
      flex-direction: column;
    }
    &__content{
      flex-direction: column-reverse;
      &__or{
        
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}