.applicationUrl {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    .scrollLinks {
        margin: 20px;
    }
    .buttons {
        margin-bottom: 15px;
        button {
            margin-right: 15px;
        }
    }
}