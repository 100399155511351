
.sorting-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  .text-value {
    margin-right: 5px;
  }
  .indicator-btn {
    cursor: pointer;
  }
  &.align-center {
    justify-content: center;
  }
  &.align-left {
    justify-content: flex-start;
  }
  &.align-right {
    justify-content: flex-end;
  }
}
