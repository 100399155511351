.bulk{
  background-color: #fff;
  height: 100%;
  &__WrapButton{
    padding: 18px 24px 10px 24px;
    .custom-button:first-child{
      margin-right:10px ;
    }
  }
 &__row{
   align-items: center;
   margin-bottom: 5px;
 }
 &__col{
   padding: unset
 }
 &__text{
   margin-bottom: 5px;
   
 }
 &__center{
   text-align: center;
   margin-bottom: 20px;
 }
}
@media (max-width: 768px){
  .bulk{
    &__text{
      margin-top: 5px;
    }
  }
}