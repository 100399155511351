.CategoryManagement{
 
  &__contain{
    color: #000000;
    margin: 32px 24px;
    &__title{
      font-size: 14px;
      line-height: 16px;
      float: left
    }
  }
 &__parent{
   display: flex;
   &__content{
     margin-left: 17px;
     &__title{
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: #04031A;
      margin-bottom: 8px;
     }
   }
 }
}
@media (max-width: 768px) {
  // .Roles{
  //   &__contain{
  //     margin: 10px 0px
  //   }
  // }
  .CategoryManagement{
    .datatable__td-categoryName, .datatable__title--categoryName{
      min-width: 400px;
    }
 }
}