@import "../../styles/variables";

#map { position: absolute; top: 0px; bottom: 0; width: 100%; height: 100vh }
.rebates{
  .mapboxgl-ctrl-top-right{
    top: unset;
    right: 0;
    bottom: 70px;
    left: 34px;
  }
  .mapboxgl-ctrl-top-right .mapboxgl-ctrl{
    float: left;
  }
  .mapboxgl-ctrl-compass{
    display: none;
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon{
    background-image: url('./../../assests/icons/iconMapZoom.svg');
  }
  .mapboxgl-ctrl-group{
    background: transparent !important;
    box-shadow: unset !important
  }
  .mapboxgl-ctrl-group button+button{
    border: unset;
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon{
    background-image: url('./../../assests/icons/iconZoomOut.svg');
  }
  .mapboxgl-ctrl button:not(:disabled):hover{
    background-color: transparent
  }
  &-marker{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: $slate;
    border-radius: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #adadb7;
    &-text{
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
    }
  }
}
