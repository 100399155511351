@import "../../../styles/variables";

.sidebar.custom-sidebar {
  width: $sidebar-width;
  border-right: 0;
  color: $white;
  background: $sidebar-color;
  transition: width 1000ms ease-in-out;
  -webkit-transition: width 1000ms ease-in-out;
  box-shadow: $sidebar-border-shadow;
  z-index: 10000000;

  .sidebar-content {
    & > * {
      display: block;
    }
    .sidebar-logo-toggle {
      display: flex;
      align-items: center;
      height: $header-height;
    }
    .sidebar-logo-toggle svg {
      cursor: pointer;
    }
    .sidebar-brand {
      font-size: $font-size-medium;
      color: $white;
      text-decoration: none;
      font-weight: bold;
      letter-spacing: .2em;
      width: $sidebar-width;
      padding-left: 26px;
      position: relative;
      cursor: pointer;
    }
   
    .sidebar-nav {
      padding-bottom: 1rem;
      padding-left: 0;
      list-style: none;
      margin-top: 3px;
      overflow: hidden;
      .sidebar-link {
        height: 44px;
        display: flex;
        align-items: center;
        padding: 0px 26px;
        color: $white;
        background-color: $sidebar-color;
        text-transform: capitalize;
        text-decoration: none;
        transition: all 200ms;
        -webkit-transition: all 200ms;
        cursor: pointer;
        .sidebar-item-sub-icon{
          margin-left: auto;
        }
        .sidebar-item-text{
          display: flex;
          flex-direction: column;
        }
        & > .sidebar-item-text  span {
          font-size: $default-font-size;
          padding-left: 20px;
          font-weight: 400;
          color: $color-text-menu;
          visibility: visible;
          opacity:1;
          transition: visibility 0.2s linear, opacity 0.2s linear, font-size 50ms 150ms;
        }
        & > .sidebar-item-text span:nth-child(2){
          color: $grey;
        }
        &.active {
          background-color: $sidebar-color-active;
          .sidebar-item-text  span {
            color: $color-white;
          }
          .sidebar-item-text span:nth-child(2){
            color: #8b8a90;
          }
          svg{
            path{
              fill-opacity: 1;
            }
          }
        }
        & > svg {
          color: white;
        }
      }
    }
    .small-logo {
      transition: opacity 0.2s linear;
      position: absolute;
      left: 10px;
      top: 35px;
      opacity: 0;
      max-width: 100%;
    }
    .logo {
      max-width: 100%;
      opacity: 1;
      transition: opacity 0.2s linear;
    }
  }
  z-index: 99999;
  position: fixed;
  min-height: 100vh;
  &:not(.toggled) {
    @media (max-width: 1024px) {
      position: fixed;
      margin-left: 0px;
      transition: margin 200ms;
      .logo, .small-logo {
        transition: none;
      }
    }
  }
  &.toggled {
    
    width: 0px;
    transition: all 1000ms ease-in-out;
    -webkit-transition: all 1000ms ease-in-out;
    .sidebar-toggle{
      display: none;
    }
   
    .sidebar-content {
      // padding-top: $header-height;
      // transition: padding-top 500ms ease-in-out;
      // -webkit-transition: padding-top 500ms ease-in-out;
      .sidebar-nav {
        a.sidebar-link {
          padding: 0px 0px 0px 18px;
          transition: all 200ms;
          -webkit-transition: all 200ms;
          & > span {
            visibility:hidden;
            
            opacity:0;
            font-size: 0em;
            transition: visibility 0.2s linear, opacity 0.2s linear, font-size 200ms;
          }
          .sidebar-item-text{
            // transition: all 600ms;
            // -webkit-transition: all 00ms;
            opacity: 0;
            transition: opacity 0.4s linear;
          }
          .sidebar-item-sub-icon{
            display: none;
            transition: display 300ms ease-in-out;
          }
        }
      }
     
      .sidebar-brand {
        margin-right: 15px;
      }
    }
    .logo {
      opacity: 0;
      transition: opacity 0.2s linear;
    }
    .small-logo {
      opacity: 1;
      transition: opacity 0.2s linear;
    }
    @media (max-width: 1024px) {
      position: fixed;
      margin-left: calc(0px - #{$sidebar-width});
      width: $sidebar-width;
      transition: margin 200ms;
      .logo, .small-logo {
        transition: none;
      }
    }
  }
   .sidebar-toggle {
      background-color: Transparent;
      background-repeat:no-repeat;
      border: none;
      cursor: pointer;
      outline:none;
      margin-right: 15px;
      transition: all 400ms;
      
    }
  
}