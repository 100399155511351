.checkbox {
  display: grid;
  align-items: center;
  grid-template-columns: 16px auto;
  column-gap: 10px;
  cursor: pointer;
}
.checkbox.warning {
  svg {
    border: 1px solid #c19e3f;
    background: #fef9e9 !important;
    color: #c19e3f;
  }
}