@import "reset.scss";
@import "mixins.scss";
@import "animations.scss";
@import "../../node_modules/react-redux-toastr/src/styles/index";
// font-face
@import url("https://p.typekit.net/p.css?s=1&k=pqf5lqy&ht=tk&f=139.140.175.176.143.144.147.148.156.157.161.162&a=39599659&app=typekit&e=css");
@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/98e3f6/000000000000000077359562/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/98e3f6/000000000000000077359562/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/98e3f6/000000000000000077359562/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/624cab/000000000000000077359558/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/624cab/000000000000000077359558/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/624cab/000000000000000077359558/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/d45b9a/000000000000000077359577/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/d45b9a/000000000000000077359577/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/d45b9a/000000000000000077359577/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/6cc429/00000000000000007735957a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/6cc429/00000000000000007735957a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/6cc429/00000000000000007735957a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"proxima-nova-condensed";
src:url("https://use.typekit.net/af/1aa497/000000000000000077359555/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/1aa497/000000000000000077359555/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/1aa497/000000000000000077359555/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"proxima-nova-condensed";
src:url("https://use.typekit.net/af/c42914/00000000000000007735955c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/c42914/00000000000000007735955c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/c42914/00000000000000007735955c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"proxima-nova-condensed";
src:url("https://use.typekit.net/af/ee884b/00000000000000007735956b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/ee884b/00000000000000007735956b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/ee884b/00000000000000007735956b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"proxima-nova-condensed";
src:url("https://use.typekit.net/af/d36e0e/00000000000000007735956e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/d36e0e/00000000000000007735956e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/d36e0e/00000000000000007735956e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"proxima-nova-extra-condensed";
src:url("https://use.typekit.net/af/88de78/00000000000000007735954f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/88de78/00000000000000007735954f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/88de78/00000000000000007735954f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"proxima-nova-extra-condensed";
src:url("https://use.typekit.net/af/8f0f9d/000000000000000077359556/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/8f0f9d/000000000000000077359556/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/8f0f9d/000000000000000077359556/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"proxima-nova-extra-condensed";
src:url("https://use.typekit.net/af/d2b9f4/000000000000000077359567/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/d2b9f4/000000000000000077359567/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/d2b9f4/000000000000000077359567/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"proxima-nova-extra-condensed";
src:url("https://use.typekit.net/af/b7df9a/00000000000000007735956a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/b7df9a/00000000000000007735956a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/b7df9a/00000000000000007735956a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
  font-family: 'Familjen Grotesk';
  src: url('../../public/fonts/FamiljenGroteskVariableFont_wght.eot');
  src: url('../../public/fonts/FamiljenGroteskVariableFont_wght.eot') format('embedded-opentype'),
       url('../../public/fonts/FamiljenGroteskVariableFont_wght.woff2') format('woff2'),
       url('../../public/fonts/FamiljenGroteskVariableFont_wght.woff') format('woff'),
       url('../../public/fonts/FamiljenGroteskVariableFont_wght.ttf') format('truetype'),
       url('../../public/fonts/FamiljenGroteskVariableFont_wght.svg#FamiljenGroteskVariableFont_wght') format('svg');
}

.tk-proxima-nova { font-family: "proxima-nova",sans-serif; }
.tk-proxima-nova-condensed { font-family: "proxima-nova-condensed",sans-serif; }
.tk-proxima-nova-extra-condensed { font-family: "proxima-nova-extra-condensed",sans-serif; }
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-base;
  font-size: $font-size-medium;
  color: $font-color-base;
  background-color: $bg;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

code {
  font-family: Muli, monospace;
}

.appLanding {
  display: flex;
  flex: 1;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.appContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.appPages {
  display: flex;
  flex: 1;
  overflow: auto;

  > div {
    width: 100%;
  }
}

.mainContent {
  position: relative;
}

.modal {
  z-index: 99999;
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

td {
  padding: 12px;
}

h2 {
  color: $viking;
}

input.small {
  width: 46px;
}

input.large {
  width: 150px;
}
// -- Modules/Activities colors -->
.module-color {
  &.module-color__border {
    border-top-color: $module-color-blue;
  }
  &.module-color__bg {
    background-color: $module-color-blue;
  }
  &.module-color__color {
    color: $module-color-blue;
  }
  &.module-color__icon {
    .module_color_fill { fill: $module-color-blue;}      
    .module_color_stroke { stroke: $module-color-blue;}      
  }
  &.eat_right {
    &.module-color__border {
      border-top-color: $module-color-blue;
    }
    &.module-color__bg {
      background-color: $module-color-blue;
    }
    &.module-color__color {
      color: $module-color-blue;
    }
    &.module-color__icon {
      .module_color_fill { fill: $module-color-blue;}          
      .module_color_stroke { stroke: $module-color-blue;}          
    }
  }
  &.learn_new_things {
    &.module-color__bg {
      background-color: $module-color-orange;
    }
    &.module-color__color {
      color: $module-color-orange;
    }
    &.module-color__border {
      border-top-color: $module-color-orange;
    }
    &.module-color__icon {
      .module_color_fill { fill: $module-color-orange;}          
      .module_color_stroke { stroke: $module-color-orange;}          
    }
  }
  &.sleep_well {
    &.module-color__bg {
      background-color: $module-color-violet;
    }
    &.module-color__color {
      color: $module-color-violet;
    }
    &.module-color__border {
      border-top-color: $module-color-violet;
    }
    &.module-color__icon {
      .module_color_fill { fill: $module-color-violet;}          
      .module_color_stroke { stroke: $module-color-violet;}          
    }
  }
  &.unset {
    &.module-color__bg {
      background-color: $module-color-unset;
    }
    &.module-color__color {
      color: $module-color-unset;
    }
    &.module-color__border {
      border-top-color: $module-color-unset;
    }
    &.module-color__icon {
      .module_color_fill { fill: $module-color-unset;}          
      .module_color_stroke { stroke: $module-color-unset;}          
    }
  }
  &.train_your_body,
  &.meditation {
    &.module-color__bg {
      background-color: $module-color-green;
    }
    &.module-color__color {
      color: $module-color-green;
    }
    &.module-color__border {
      border-top-color: $module-color-green;
    }
    &.module-color__icon {
      .module_color_fill { fill: $module-color-green;}          
      .module_color_stroke { stroke: $module-color-green;}          
    }
  }
  &.mindset,
  &.braintraining {
    &.module-color__bg {
      background-color: $module-color-yellow;
    }
    &.module-color__color {
      color: $module-color-yellow;
    }
    &.module-color__border {
      border-top-color: $module-color-yellow;
    }
    &.module-color__icon {
      .module_color_fill { fill: $module-color-yellow;}          
      .module_color_stroke { stroke: $module-color-yellow;}          
    }
  }
  &.uncover_your_purpose,
  &.journal {
    &.module-color__bg {
      background-color: $module-color-pink;
    }
    &.module-color__color {
      color: $module-color-pink;
    }
    &.module-color__border {
      border-top-color: $module-color-pink;
    }
    &.module-color__icon {
      .module_color_fill { fill: $module-color-pink;}          
      .module_color_stroke { stroke: $module-color-pink;}          
    }
  }
  &.socialize_with_others,
  &.video {
    &.module-color__bg {
      background-color: $module-color-pink;
    }
    &.module-color__color {
      color: $module-color-pink;
    }
    &.module-color__border {
      border-top-color: $module-color-pink;
    }
    &.module-color__icon {
      .module_color_fill { fill: $module-color-pink;}          
      .module_color_stroke { stroke: $module-color-pink;}          
    }
  }
  &.your_unique_self,
  &.reading {
    &.module-color__bg {
      background-color: $module-color-green;
    }
    &.module-color__color {
      color: $module-color-green;
    }
    &.module-color__border {
      border-top-color: $module-color-green;
    }
    &.module-color__icon {
      .module_color_fill { fill: $module-color-green;}          
      .module_color_stroke { stroke: $module-color-green;}          
    }
  }
  &.reduce_stress,
  &.survey {
    &.module-color__bg {
      background-color: $module-color-orange;
    }
    &.module-color__color {
      color: $module-color-orange;
    }
    &.module-color__border {
      border-top-color: $module-color-orange;
    }
    &.module-color__icon {
      .module_color_fill { fill: $module-color-orange;}          
      .module_color_stroke { stroke: $module-color-orange;}          
    }
  }
}

#root {
  -webkit-box-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #F3F3F3;
}


.main {
  flex: 1;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  .header-text-icon {
    display: none !important;
  }
  @media (max-width: 1024px) {
    z-index: 1;
    .custom-header, .footer {
      width: 100%;
    }
  }
  @media (min-width: 1025px) {
    // transition: padding-left 200ms ease-in-out;
    // -webkit-transition: padding-left 200ms ease-in-out;
    &:not(.toggled) {
      .custom-header{
        transition: padding-left 1000ms ease-in-out;
        -webkit-transition: padding-left 1000ms ease-in-out;
        padding-left: $sidebar-width;
      }
    }
    .custom-header{
      transition: padding-left 1000ms ease-in-out;
        -webkit-transition: padding-left 1000ms ease-in-out;
      padding-left: 0;
    }
   
  }
  &.no-menu {
    padding: 0;
  }
}
.main.toggled{
  // .custom-header{
  //   z-index: 1000000;
   
  // }
  .header-text-icon {
    display: flex !important;
  }

}

.container {
  position: relative;
  background-color: transparent;
  flex: 1;

  .custom-empty-state.centralized {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

  }
}

.footer {
  height: $footer-height;
  font-size: $font-size-small;
  background-color: $concrete;
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: absolute;
  bottom: 0;
  .footer-links-wrapper {
    display: flex;
    flex-direction: row;
    margin-right: 15px;
    .footer-link {
      color: $text-secondary-color;
      &:not(:last-of-type)::after {
        display: inline-flex;
        content: '|';
        margin: 0 10px;
      }
    }
  }
  .copyright-text {
    text-align: center;
  }
}
.content-wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $concrete;
  &.white {
    background-color: white;
  }
}
.protected-route-wrapper {
  // min-height: calc(100% - #{$footer-height});
  // margin: 0px 10px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  & > div {
    flex: 1;
  }
}
.page-content {
  padding: 20px;
  margin: 0;
}
.CustormScrollBar {
  flex: unset !important;
  div::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
    display: none;
  }
  
  /* background of the scrollbar except button or resizer */
  div::-webkit-scrollbar-track {
    background-color: #fff;
    display: none
  }
  
  /* scrollbar itself */
  div::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
    display: none
  }
  
  /* set button(top and bottom of the scrollbar) */
  div::-webkit-scrollbar-button {
    display:none;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
@media (max-width: 1023px) {
  .page-content {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 90%;
  }
}
.navbar {
  border-bottom: 0;
}
.bg-white {
  background-color: #fff !important;
}
.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.page-toolbar, .custom-tabs-header {
  position: absolute;
  top: 25px;
  right: 30px;
}
.page-toolbar {
  display: flex;
  z-index: 99;
  flex-direction: column-reverse;
  align-items: center;
  & > * {
    margin-left: 20px;
  }
}
.page-header {
  padding: 20px 0;
  font-size: 20px !important;
}
.was-validated .form-control:valid, .form-control.is-valid {
  border-color: $input-border-color;
  padding: $input-padding-y $input-padding-x;
  background-image: none;
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: $input-color;
}
.invisible {
  visibility: hidden !important;
}
button {
  outline: none !important;
  box-shadow: none !important;
}
.noselect {
  &,
  * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.hide {
  display: none !important;
}
.rounded {
  border-radius: 2px !important;
}

.dash {
  border-style: dashed;
}

.svg-icon {
  .icon-bg {
    fill: $icon-bg;
  }
  .icon-color {
    stroke-linejoin:round;
    stroke:$icon-color;
    fill: $icon-bg;
  }

  &.active {
    .icon-bg {
      fill: $icon-bg-active;
    }
    .icon-color {
      stroke-linejoin:round;
      stroke:$icon-color-active;
      fill: $icon-bg-active;
    }
  }
}

.redux-toastr {
  .bottom-right,
  .bottom-left,
  .top-right,
  .top-left {
    width: 400px;
    &.bottom-right,
    &.bottom-left {
      bottom: $footer-height;
      top: unset !important;
    }
    &.top-right,
    &.top-left {
      top: $header-height;
      bottom: unset !important;
    }
  }
  .toastr {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    height: 100%;
    min-height: unset;
    display: inline-flex;
    & > div {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
    }

    .toastr-icon {
      margin-top: 0 !important;
    }
    &.rrt-error {
      background-color: lighten($toast-error, $amount: 40);
      border-left: 3px solid $toast-error;
      .toastr-icon {
        fill: $toast-error; 
        g {
          transform: translate(5px, 5px);
        }
      }
      .close-toastr {
        font-size: 15px !important;
        color: $toast-error !important;
        font-weight: 900;
      }
    }
    &.rrt-success {
      background-color: lighten($toast-success, $amount: 40);
      border-left: 3px solid $toast-success;
      .toastr-icon {
        fill: $toast-success; 
        g {
          transform: scale(.8, .8) translate(5px, 5px);
        }
      }
      .close-toastr {
        font-size: 15px !important;
        color: $toast-success !important;
        font-weight: 900;
      }
    }

    .rrt-left-container {
      width: 40px !important;
      height: 40px;
      .rrt-holder {
        width: 100%;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: unset !important;
        margin-top: 0 !important;
        top: 0;
        left: 5px;
        line-height: 60px;
      }
    }
    .rrt-text {
      line-height: 2em;
    }

    .rrt-right-container {
      width: 40px;
      height: 40px;
      position: relative;
      .close-toastr {
        width: 100%;
        height: 100%;
      }
    }
    
    .rrt-middle-container {
      width: calc(100% - 40px - 40px);
      margin-left: 40px !important;
      position: relative;
      float: left;
      font-family: sans-serif;
      font-size: .8rem;
      text-align: left;
      color: $text-primary-color;
      display: inline-flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 0;
    }
  }
}

// general for day header
.days-header {
  display: flex;
  flex-direction: row;
  & > * {
    flex: 1;
  }
}
.days-header {
  margin-bottom: 5px;
  text-align: center;
  height: 54px;
  border-top: 1px solid $border-color-light;
  border-bottom: 1px solid $border-color-light;
  .day-header {
    height: 100%;
    color: $text-primary-color;
    margin-bottom: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    border-right: 1px solid $border-color-light;
    font-weight: 600;
    &:last-child {
      border: none;
    }
  }
}
.btn-outline-light .button-text {
  color: $text-secondary-color-light;
}
.select {
  span[class*="-indicatorSeparator"] {
    display: none;
  }
}

.custom-button.btn-md,
.form-control,
.css-yk16xz-control {
  height: 40px;
}

.form-control:disabled,
.form-control[readonly],
.css-1fhf3k1-control {
  background-color: $form-field-disabled-bg-color;
  border-color: $form-field-disabled-border-color;
}

// .custom-button.btn-md {
//   min-width: 120px;
// }

.hiddenDesktop{
  display: none !important;
}

.displayNone{
  display: none;
}
.hidden{
  visibility: hidden;
}
@media (max-width: 768px) {
  .hiddenMobile{
    display: none !important;
  }
  .hiddenDesktop{
    display: unset !important;
  }
  .footerButton{
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 24px;
    left: 50%;
    margin-left: -45px;
    .username-dropdown {
      margin-left: 15px;
    }
    .iconHoverQuestion{
      margin-right: 15px;
    }
  }
  
  .header-text{
    .custom-contact-group{
      margin-left: -10px;
    }
  }
}

.scroll-wrap{
  // height: calc(100% - 56px) !important ;
  margin-top: 56px;
}

.flex-left-view {
  > div:first-child {
    transition: padding-left 1000ms ease-in-out;
    -webkit-transition: padding-left 1000ms ease-in-out;
    padding-left: $sidebar-width;
  }
}

.flex-right-view {
  > div:first-child {
    transition: padding-left 1000ms ease-in-out;
    -webkit-transition: padding-left 1000ms ease-in-out;
    padding-left: 0;
  }
}