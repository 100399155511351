// -- define variables -->
@import '../../../styles/variables';

.custom-month-view {
  border: 1px solid $border-color-light;
  // border-right: 1px solid $border-color-light;
  .month-view-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 1px;
    background-color: $border-color-light;
  }

  .month-cell {
    background-color: $white;
    &.dow__1 {
      grid-column: 1;
    }
    &.dow__2 {
      grid-column: 2;
    }
    &.dow__3 {
      grid-column: 3;
    }
    &.dow__4 {
      grid-column: 4;
    }
    &.dow__5 {
      grid-column: 5;
    }
    &.dow__6 {
      grid-column: 6;
    }
    &.dow__7 {
      grid-column: 7;
    }
    &.cell_last-month,
    &.cell_next-month {
      background-color: $white;
    }
  }
}
