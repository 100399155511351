
@import "../../../styles/variables";

.custom-confirm-modal {
  .close {
    &:focus {
      outline: none;
    }
  }
  .confirm-modal-header {
    border-bottom: 0px;
  }
  .modal-title {
    font-size: $font-size-medium;
    color: $text-primary-color;
    font-weight: 700;
  }
  .modal-text-message {
    font-size: $font-size-small;
  }
  .confirm-modal-footer {
    border-top: 0px;
  }
  .modal-footer {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    column-gap: 10px;
  }
}