@import "../../styles/variables";

.my-profile {
  padding: 48px 120px;
  .title{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 48px;
  }
  .my-profile-card{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
  }

  .profile-menu {
    padding: 20px 0px;
    width: 220px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #fbfdfd;
    border-right: 1px solid #e4e8e7;
    &-tabs-button{
      &__icon {
        display: none;
      }
    }
  }
  .profile-content {
    padding: 48px 60px;
    flex: 1;
    .parentAvatar{
      display: flex;
      align-items: center;
    }

    .parentAvatarContent{
      margin-left: 24px;
      .title{
        color: rgba(4, 3, 26, 0.5);
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 8px;
      }
      .text{
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: rgba(4, 3, 26, 0.5);
        margin-bottom: 2px;
      }
    }

    .text_link{
      cursor: pointer;
      color: $orange;
    }
    .text_link_parent{
      margin-top: 6px;
      margin-bottom: 24px;
    }
    .profile-forms-wrapper {
      max-width: 640px;
    }
    .profile-content-title{
      font-size: 26px;
      line-height: 32px;
      color: #000000;
    }
    .profile-content-parent{
      margin-top: 24px;
    }
    .profile-content-titleSub{
      color: rgba(4, 3, 26, 0.5);
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
    }
    .btn-md{
      min-width: unset;
      width: 130px;
    }
  }

  .personal-form-wrapper {
    margin-top: 24px;
    .top-label-form-group{
      margin-bottom: 18px;
    }
  }
  
  .preferences_title{
    font-size: 14px;
    line-height: 16px;
    color: rgba(4, 3, 26, 0.5);
    margin-top: 12px;
    margin-bottom: 32px;
  }
  .Switches-wrapper{
    margin-bottom: 16px;
  }
  @media (max-width: 768px) {
    padding: unset;
    .custom-avatar-wrapper{
      width: 56px;
    }
    .profile-content-title{
      display: none;
    }
    .title{
      display: none
    }
    .my-profile-card{
      flex-direction: column;
      .profile-content{
        padding: 10px;
      }
      .profile-menu {
        padding: unset;
        width: 100%;
        border-radius: 0px;

        background-color: #fff;
        border-right: unset;
       }
       .profile-menu-tabs-button{
         margin: 10px;
         &__icon {
          display: unset;
          position: absolute;
          left: 30px;
        }
     
       }
    }
    .icon-wrapper{
      width: 100%;
      text-align: center;
    }
  
  }
}